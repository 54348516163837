define("js2-admin/components/subject-autosuggest", ["exports", "js-common/components/subject-autosuggest"], function (_exports, _subjectAutosuggest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _subjectAutosuggest.default;
    }
  });
});