define("js2-admin/instance-initializers/raven-setup", ["exports", "ember-cli-sentry", "js2-admin/config/environment"], function (_exports, _emberCliSentry, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'sentry-setup',
    initialize: function initialize(application) {
      (0, _emberCliSentry.initialize)(application.lookup ? application : application.container, _environment.default);
    }
  };
  _exports.default = _default;
});