define("js2-admin/pods/matter/subjects/info/notes/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "36nrrnfl",
    "block": "{\"symbols\":[\"SubjectsNotesContainer_ANGLE_0\"],\"statements\":[[4,\"let\",[[29,\"component\",[\"subjects/notes-container\"],null]],null,{\"statements\":[[6,[24,1,[]],[],[[\"@newNote\",\"@subjectId\",\"@fetchSubject\",\"@side\"],[[24,0,[\"newNote\"]],[25,[\"model\",\"subjectId\"]],[29,\"route-action\",[\"fetchSubject\"],null],\"right\"]]]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-admin/pods/matter/subjects/info/notes/template.hbs"
    }
  });

  _exports.default = _default;
});