define("js2-admin/pods/matter/documents/edit/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      save: function save() {
        var self = this;
        var doc = this.model;
        doc.save().then(function () {
          self.transitionToRoute('matter.documents');
        });
      }
    }
  });

  _exports.default = _default;
});