define("js2-admin/pods/components/subjects/edit-subject-modal/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this.set('reRender', false);
      Ember.run.next(function () {
        _this.set('reRender', true);
      });
    },
    actions: {
      toggleModal: function toggleModal() {
        (0, _jquery.default)('#editSubjectModal').modal('toggle');
      },
      close: function close() {
        this.subject.rollbackAttributes();
        this.sendAction('toggleModal');
      },
      changeSubjectValue: function changeSubjectValue(newValue, field) {
        this.set('subject.' + field.get('name'), newValue);
      }
    }
  });

  _exports.default = _default;
});