define("js2-admin/pods/components/matter/subjects/is-monitored-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "elaHyOys",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[30,[\"text-center \",[29,\"if\",[[29,\"eq\",[[25,[\"row\",\"monitor\"]],1],null],\"is-monitored-cell\",\"is-monitored-empty-cell\"],null]]]],[9],[0,\"\\n  \"],[5,\"sc-icon-button\",[],[[\"@icon\",\"@color\",\"@onClick\"],[\"check\",\"jade\",[29,\"action\",[[24,0,[]],[25,[\"tableActions\",\"toggleMonitoring\"]],[25,[\"row\",\"content\"]]],null]]]],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-admin/pods/components/matter/subjects/is-monitored-cell/template.hbs"
    }
  });

  _exports.default = _default;
});