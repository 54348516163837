define("js2-admin/pods/components/smm/table-tools/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // quickSearchField: 'tm_code',
    selectedRows: Ember.computed.reads('toolsParams.selectedRows'),
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    statusOptions: ['Completed', 'Pending', 'New', 'Failed', 'Canceled', 'All'],
    selectedStatus: Ember.computed.reads('toolsParams.initialStatus'),
    filterByStatus: function filterByStatus() {
      var allColumns = this.allColumns;
      var statusColumn = allColumns.findBy('displayName', 'Status');

      if (statusColumn) {
        statusColumn.set('advFilterOperator', this.operators.findBy('value', 'contains'));
        var selectedStatus = this.selectedStatus;

        if (selectedStatus === 'All') {
          statusColumn.set('advFilterValue', null);
        } else {
          statusColumn.set('advFilterValue', selectedStatus);
        }
      }

      this.send('applyFilter');
    },
    actions: {
      changeFilterStatus: function changeFilterStatus(newStatus) {
        this.set('selectedStatus', newStatus);
        this.filterByStatus();
      }
    }
  });

  _exports.default = _default;
});