define("js2-admin/pods/me/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sRShiNXh",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"id\",\"mainWrapper\"],[11,\"class\",\"jsi-app wrapper\"],[9],[0,\"\\n\\n  \"],[2,\" Full Width Column \"],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"content-page\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"content\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"container-fluid\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"children-auto-flex\"],[9],[0,\"\\n\\n          \"],[1,[23,\"outlet\"],false],[0,\"\\n\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[2,\" /.content \"],[0,\"\\n  \"],[10],[0,\"\\n  \"],[2,\" /.content-wrapper \"],[0,\"\\n\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-admin/pods/me/template.hbs"
    }
  });

  _exports.default = _default;
});