define("js2-admin/pods/me/controller", ["exports", "js-admin-common/mixins/with-full-height-pages"], function (_exports, _withFullHeightPages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_withFullHeightPages.default, {
    _fullHeightPages: []
  });

  _exports.default = _default;
});