define("js2-admin/models/subject-workflow-column", ["exports", "js-common/models/subject-workflow-column"], function (_exports, _subjectWorkflowColumn) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _subjectWorkflowColumn.default.extend({});

  _exports.default = _default;
});