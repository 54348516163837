define("js2-admin/pods/components/matter/subjects/monitoring-notes-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "E6U5UCUQ",
    "block": "{\"symbols\":[\"note\"],\"statements\":[[4,\"each\",[[25,[\"row\",\"latestMonitoringNotes\"]]],null,{\"statements\":[[0,\"  \"],[7,\"li\"],[9],[0,\"\\n    \"],[1,[29,\"moment-format\",[[24,1,[\"createdOn\"]],\"MM/DD/YYYY @ hh:mm A\"],null],false],[0,\" by \"],[1,[24,1,[\"createdBy\",\"fullName\"]],false],[7,\"br\"],[9],[10],[0,\"\\n\"],[4,\"if\",[[25,[\"row\",\"expanded\"]]],null,{\"statements\":[[0,\"      \"],[1,[29,\"nl-2br\",[[24,1,[\"body\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[29,\"nl-2br\",[[29,\"truncate\",[[24,1,[\"body\"]],150],null]],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n  \"],[7,\"li\"],[9],[0,\" \"],[10],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"  \"],[7,\"li\"],[9],[0,\"\\n    -\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-admin/pods/components/matter/subjects/monitoring-notes-cell/template.hbs"
    }
  });

  _exports.default = _default;
});