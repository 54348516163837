define("js2-admin/pods/search-documents/controller", ["exports", "js-admin-common/mixins/document-search-controller"], function (_exports, _documentSearchController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_documentSearchController.default, {
    store: Ember.inject.service()
  });

  _exports.default = _default;
});