define("js2-admin/pods/components/smm/datetime-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3RCNLReY",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"moment-format\",[[25,[\"value\"]],\"MM/DD/YYYY hh:mm a\"],null],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-admin/pods/components/smm/datetime-cell/template.hbs"
    }
  });

  _exports.default = _default;
});