define("js2-admin/mixins/search-db-route", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "js-common/mixins/same-route-transition-loading-mixin", "js-common/mixins/crud/error", "js2-admin/config/environment"], function (_exports, _toConsumableArray2, _sameRouteTransitionLoadingMixin, _error, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_sameRouteTransitionLoadingMixin.default, _error.default, {
    notify: Ember.inject.service(),
    currentMatter: Ember.inject.service(),
    showDbMemos: false,
    displayMemosCount: false,
    queryParams: {
      with_loc: {
        refreshModel: true
      },
      tab: {
        refreshModel: false
      },
      state: {
        refreshModel: true
      },
      country: {
        refreshModel: true
      },
      tags: {
        refreshModel: true
      },
      q: {
        refreshModel: true
      },
      qall: {
        refreshModel: true
      },
      show_hidden: {
        refreshModel: true
      },
      refresh_dbs: {
        refreshModel: true
      },
      filter_by_created: {
        refreshModel: true
      },
      filter_by_updated: {
        refreshModel: true
      },
      activity_days: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var showHidden = false;
      showHidden = params.show_hidden === true;
      delete params.refresh;

      if (!Ember.isArray(params.tags)) {
        params.tags = [];
      }

      var isLatestTabSelected = params.tab === 'latest';
      var excludeCountry = false;

      if (params.tab !== 'states' && params.tab !== 'countries' && params.tab !== 'latest') {
        params.tab = 'general';
        excludeCountry = 1; // exclude any databases related to a country
      }

      var stateOnly = false;

      if (params.tab === 'states' && params.tags.length === 1) {
        stateOnly = true; // show only dbs that have a state tag, but NOT a county tag
      }

      if (Ember.isEmpty(params.q)) {
        if (params.tags.length === 0 && params.with_loc === true) {
          params.q = 'please not create a database with this name';
        } else {
          params.q = '';
        }
      }

      var relatedTo = '';

      if (params.q && params.tab === 'states' && params.tags.length === 0) {
        relatedTo = 'states';
      }

      if (params.q && params.tab === 'countries' && params.tags.length === 0) {
        relatedTo = 'countries';
      }

      var filters = {
        db_tag_id: params.tags.uniq(),
        db_tag_operator: params.tab === 'states' ? 'AND' : 'OR',
        status: showHidden ? '*' : '1',
        excludeCountry: excludeCountry,
        stateOnly: stateOnly,
        name: "*".concat(params.q, "*"),
        relatedTo: relatedTo,
        filter_by_created: params.filter_by_created,
        filter_by_updated: params.filter_by_updated,
        activity_days: params.activity_days,
        load_memos: this.get('displayMemosCount') && !this.get('currentMatter.id') ? 1 : 0,
        custom_endpoint: 'dbs/research'
      };

      if (Ember.isPresent(params.qall)) {
        Ember.assign(filters, {
          qall: "*".concat(params.qall, "*")
        });
        delete filters['name'];
      }

      var queryForDbs = true;

      if (!isLatestTabSelected && params.tags.length === 0 && params.q === '' && params.qall === '') {
        queryForDbs = false;
      }

      if (isLatestTabSelected && !params.filter_by_updated && !params.filter_by_created) {
        queryForDbs = false;
      }

      return Ember.RSVP.hash({
        databases: !queryForDbs ? [] : this.store.query('db', filters),
        consolidatedTags: this.store.query('db-tag', {
          parent_id: [_environment.default.APP.tags.stateCategory, _environment.default.APP.tags.countryCategory, params.state, 'NULL']
        }),
        matter_id: params.matter_id,
        params: params,
        displayMemosCount: this.get('displayMemosCount'),
        db_memos: this.get('currentMatter.id') ? this.store.query('db-memo', {
          matter_id: this.get('currentMatter.id')
        }) : []
      }).then(function (response) {
        response.states = response.consolidatedTags.filterBy('parentId', _environment.default.APP.tags.stateCategory).sortBy('name');
        response.counties = Ember.isNone(response.params.state) ? [] : response.consolidatedTags.filterBy('parentId', parseInt(response.params.state, 10)).sortBy('name');
        response.countries = response.consolidatedTags.filterBy('parentId', _environment.default.APP.tags.countryCategory).sortBy('name');
        response.general_tags = response.consolidatedTags.filterBy('parentId', null).sortBy('name').map(function (tag) {
          tag.set('isSelected', response.params.tags.includes(tag.get('id')));
          return tag;
        });
        delete response.consolidatedTags;
        return response;
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var params = model.params;
      var otherTags = [];
      params.tags.forEach(function (tag) {
        var state = model.states.findBy('id', tag);

        if (Ember.isPresent(state)) {
          otherTags.push(state);
        }

        var country = model.countries.findBy('id', tag);

        if (Ember.isPresent(country)) {
          otherTags.push(country);
        }

        var county = model.counties.findBy('id', tag);

        if (Ember.isPresent(county)) {
          otherTags.push(county);
        }
      });
      model.countries = model.countries.rejectBy('id', _environment.default.APP.tags.USACountry.toString());
      model.general_tags = model.general_tags.rejectBy('id', _environment.default.APP.tags.notOnline.toString());
      model.general_tags = model.general_tags.rejectBy('id', _environment.default.APP.tags.notPublic.toString());
      model.general_tags = model.general_tags.rejectBy('id', _environment.default.APP.tags.countryCategory.toString());
      controller.set('tab', Ember.isPresent(params.qall) ? 'search' : params.tab);
      controller.set('q', params.q);
      controller.set('qall', params.qall);
      controller.set('show_hidden', params.show_hidden);
      controller.set('tagSelection', [].concat((0, _toConsumableArray2.default)(model.general_tags.filterBy('isSelected')), otherTags));
      controller.set('matter_id', model.matter_id);
      controller.set('displayMemosCount', model.displayMemosCount);
    },
    actions: {
      refresh: function refresh() {
        this.refresh();
      },

      /** Actions to handle filter changes **/
      tagSelectionChange: function tagSelectionChange(tagSelection) {
        var controller = this.controllerFor(this.get('routeName'));
        controller.set('tags', tagSelection.map(function (tag) {
          return tag.get('id');
        }));
        var state;
        tagSelection.forEach(function (tag) {
          var stateTag = controller.get('model.states').findBy('id', tag.get('id'));

          if (Ember.isEmpty(state) && Ember.isPresent(stateTag)) {
            state = stateTag.get('id');
          }
        });
        controller.set('state', state);
        var country;
        tagSelection.forEach(function (tag) {
          var countryTag = controller.get('model.countries').findBy('id', tag.get('id'));

          if (Ember.isEmpty(country) && Ember.isPresent(countryTag)) {
            country = countryTag.get('id');
          }
        });
        controller.set('country', country);
      },
      queryChange: function queryChange(newQuery) {
        var controller = this.controllerFor(this.get('routeName'));
        controller.set('q', newQuery);
      },
      queryAllChange: function queryAllChange(newQuery) {
        var controller = this.controllerFor(this.get('routeName'));
        controller.set('qall', newQuery);
      },
      hiddenChange: function hiddenChange(showHidden) {
        var controller = this.controllerFor(this.get('routeName'));
        controller.set('show_hidden', showHidden);
      },
      useLocationChange: function useLocationChange(withLocation) {
        var controller = this.controllerFor(this.get('routeName'));
        controller.set('with_loc', withLocation);
      },

      /** Actions to handle CRUD of dbs **/
      saveTag: function saveTag(newTagName) {
        var _this = this;

        var tag = this.store.createRecord('db-tag', {
          name: newTagName
        });
        return tag.save().then(function () {
          _this.get('notify').success('Tag has been created successfully');

          _this.refresh();

          return true;
        }, function () {
          _this.validationReport(tag);

          return false;
        });
      },
      createDbMemo: function createDbMemo(selectedDatabases, transition) {
        var _this2 = this;

        if (typeof selectedDatabases.get('id') !== 'undefined') {
          selectedDatabases = [selectedDatabases];
        }

        var currentMatter = this.get('currentMatter');
        this.store.findRecord('matter', currentMatter.id).then(function (matter) {
          selectedDatabases.forEach(function (db) {
            if (db.get('url') && transition) {
              window.open(db.get('url'), '_blank');
            }
          });

          if (selectedDatabases.length === 1) {
            var db = selectedDatabases.get('firstObject');

            _this2.transitionTo('matter.db-memos.for-db', db.get('id'), {
              queryParams: {
                edit_id: 'new',
                subject_id: undefined,
                db_id: undefined
              }
            });
          } else {
            _this2.transitionTo('matter.db-memos.browse-memos');
          }
        });
      },
      showMemos: function showMemos(db) {
        var controller = this.controllerFor(this.get('routeName'));
        controller.set('memos', db.get('dbMemos'));
        controller.set('showDbMemos', true);
      },
      closeShowMemos: function closeShowMemos() {
        var controller = this.controllerFor(this.get('routeName'));
        controller.set('showDbMemos', false);
      }
    }
  });

  _exports.default = _default;
});