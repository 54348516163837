define("js2-admin/pods/matter/updates/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7RXGZooC",
    "block": "{\"symbols\":[\"range\",\"event\"],\"statements\":[[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col-md-12\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"recent-activity card-box\"],[9],[0,\"\\n            \"],[7,\"h3\"],[11,\"class\",\"title\"],[9],[0,\"Latest case updates\"],[10],[0,\"\\n\"],[4,\"each\",[[25,[\"groupedUpdates\"]]],null,{\"statements\":[[0,\"              \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n                  \"],[7,\"div\"],[11,\"class\",\"col-md-2 text-center\"],[9],[0,\"\\n                      \"],[7,\"h4\"],[9],[1,[24,1,[\"value\"]],false],[10],[0,\"\\n                  \"],[10],[0,\"\\n                  \"],[7,\"div\"],[11,\"class\",\"col-md-8\"],[9],[0,\"\\n\"],[4,\"each\",[[24,1,[\"items\"]]],null,{\"statements\":[[0,\"                        \"],[1,[29,\"matter-update\",null,[[\"event\"],[[24,2,[]]]]],false],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"                      \"],[7,\"hr\"],[9],[10],[0,\"\\n                  \"],[10],[0,\"\\n                  \"],[7,\"div\"],[11,\"class\",\"col-md-2\"],[9],[10],[0,\"\\n              \"],[10],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"              \"],[7,\"p\"],[9],[0,\"No new updates\"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[1,[23,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-admin/pods/matter/updates/template.hbs"
    }
  });

  _exports.default = _default;
});