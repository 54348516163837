define("js2-admin/pods/matter/documents/search/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "269yL1nN",
    "block": "{\"symbols\":[\"DocumentsTableRow_ANGLE_0\",\"document\",\"Row\"],\"statements\":[[4,\"let\",[[29,\"component\",[\"documents/table-row\"],null]],null,{\"statements\":[[5,\"document-search\",[],[[\"@matterId\",\"@documentTypes\",\"@subjects\",\"@searchDocuments\",\"@documents\",\"@query\",\"@terms\",\"@docName\",\"@selectedSubject\"],[[25,[\"currentMatter\",\"id\"]],[25,[\"model\",\"documentTypes\"]],[25,[\"model\",\"subjects\"]],[29,\"route-action\",[\"search\"],null],[23,\"documents\"],[29,\"readonly\",[[25,[\"query\"]]],null],[29,\"readonly\",[[25,[\"terms\"]]],null],[29,\"readonly\",[[25,[\"docName\"]]],null],[29,\"readonly\",[[25,[\"selectedSubject\"]]],null]]],{\"statements\":[[0,\"\\n  \"],[5,\"sc-container\",[],[[\"@width\"],[\"fluid\"]],{\"statements\":[[0,\"\\n    \"],[6,[24,3,[]],[],[[],[]],{\"statements\":[[0,\"\\n      \"],[6,[24,1,[]],[[13,\"class\",\"mb-2\"]],[[\"@document\",\"@matter_id\",\"@fileTypes\",\"@subjects\",\"@relationshipFieldEnabled\"],[[24,2,[]],[25,[\"currentMatter\",\"id\"]],[25,[\"model\",\"documentTypes\"]],[25,[\"model\",\"subjects\"]],false]]],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[3]}],[0,\"\\n\"]],\"parameters\":[2]}]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-admin/pods/matter/documents/search/template.hbs"
    }
  });

  _exports.default = _default;
});