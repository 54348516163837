define("js2-admin/pods/components/monitoring-document-edit/row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9CIxzaw7",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-md-12\"],[9],[0,\"\\n    \"],[1,[25,[\"fileToUpload\",\"name\"]],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-md-6\"],[9],[0,\"\\n    \"],[1,[29,\"input\",null,[[\"id\",\"type\",\"checked\"],[[29,\"concat\",[[25,[\"elementId\"]],\"_public_mass\"],null],\"checkbox\",[25,[\"fileToUpload\",\"viewable\"]]]]],false],[0,\"\\n    \"],[7,\"label\"],[12,\"for\",[29,\"concat\",[[25,[\"elementId\"]],\"_public_mass\"],null]],[9],[0,\"Public\"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-md-6 text-right\"],[9],[0,\"\\n    \"],[1,[29,\"show-filesize\",[[25,[\"fileToUpload\",\"size\"]]],null],false],[0,\"\\n     \"],[7,\"span\"],[11,\"class\",\"link\"],[9],[1,[29,\"fa-icon\",[\"remove\"],null],false],[3,\"action\",[[24,0,[]],\"remove\",[25,[\"fileToUpload\"]]]],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-md-12\"],[11,\"style\",\"border-top: 1px solid #e4e4e4;margin-top: 5px;margin-bottom: 5px;\"],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-admin/pods/components/monitoring-document-edit/row/template.hbs"
    }
  });

  _exports.default = _default;
});