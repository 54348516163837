define("js2-admin/components/analytics-modules/product-use", ["exports", "js-common/components/analytics-modules/product-use"], function (_exports, _productUse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _productUse.default;
    }
  });
});