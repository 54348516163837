define("js2-admin/adapters/subject-workflow-column", ["exports", "js2-admin/config/environment", "js2-admin/adapters/application"], function (_exports, _environment, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    pathForType: function pathForType()
    /* modelName */
    {
      return 'subject_workflow_v2s/columns';
    },
    urlForFindAll: function urlForFindAll()
    /* modelName, snapshot */
    {
      return "".concat(_environment.default.APP.restDestination, "/").concat(_environment.default.APP.restNameSpace, "/subject_workflow_v2s/columns");
    }
  });

  _exports.default = _default;
});