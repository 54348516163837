define("js2-admin/components/x-preview-document", ["exports", "js-common/components/x-preview-document"], function (_exports, _xPreviewDocument) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _xPreviewDocument.default;
    }
  });
});