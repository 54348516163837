define("js2-admin/pods/components/smm/assign-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MyqcLB9S",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"value\"]]],null,{\"statements\":[[0,\"    \"],[1,[29,\"get\",[[25,[\"row\",\"content\"]],\"assignedTo.fullName\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"a\"],[11,\"class\",\"btn btn-link\"],[11,\"href\",\"#\"],[9],[0,\"\\n        Assign to me\\n    \"],[3,\"action\",[[24,0,[]],[25,[\"tableActions\",\"assignToMe\"]],[25,[\"row\"]]]],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-admin/pods/components/smm/assign-cell/template.hbs"
    }
  });

  _exports.default = _default;
});