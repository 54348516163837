define("js2-admin/pods/matter/info/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/i9rPmSN",
    "block": "{\"symbols\":[\"Card\",\"nav\"],\"statements\":[[5,\"sc-container\",[[13,\"class\",\"pb-4\"]],[[\"@width\"],[\"fluid\"]],{\"statements\":[[0,\"\\n    \"],[5,\"sc-card\",[],[[],[]],{\"statements\":[[0,\"\\n        \"],[5,\"bs-nav\",[[13,\"class\",\"px-3\"]],[[\"@type\"],[\"tabs\"]],{\"statements\":[[0,\"\\n            \"],[6,[24,2,[\"item\"]],[],[[],[]],{\"statements\":[[0,\"\\n                \"],[4,\"link-to\",[\"matter.info.notes\"],[[\"class\"],[\"nav-link text-sm\"]],{\"statements\":[[0,\"Case Notes\"]],\"parameters\":[]},null],[0,\"\\n            \"]],\"parameters\":[]}],[0,\"\\n            \"],[6,[24,2,[\"item\"]],[],[[],[]],{\"statements\":[[0,\"\\n                \"],[4,\"link-to\",[\"matter.info.report-components\"],[[\"class\"],[\"nav-link text-sm\"]],{\"statements\":[[0,\"Components\"]],\"parameters\":[]},null],[0,\"\\n            \"]],\"parameters\":[]}],[0,\"\\n            \"],[6,[24,2,[\"item\"]],[],[[],[]],{\"statements\":[[0,\"\\n                \"],[4,\"link-to\",[\"matter.info.quick-links\"],[[\"class\"],[\"nav-link text-sm\"]],{\"statements\":[[0,\"Quick Links\"]],\"parameters\":[]},null],[0,\"\\n            \"]],\"parameters\":[]}],[0,\"\\n        \"]],\"parameters\":[2]}],[0,\"\\n        \"],[5,\"sc-scroll-view\",[],[[],[]],{\"statements\":[[0,\"\\n            \"],[6,[24,1,[\"body\"]],[],[[],[]],{\"statements\":[[0,\"\\n                \"],[1,[23,\"outlet\"],false],[0,\"\\n            \"]],\"parameters\":[]}],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n    \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-admin/pods/matter/info/template.hbs"
    }
  });

  _exports.default = _default;
});