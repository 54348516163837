define("js2-admin/components/sc-common-components", ["exports", "js-common/components/sc-common-components"], function (_exports, _scCommonComponents) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _scCommonComponents.default;
    }
  });
});