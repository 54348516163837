define("js2-admin/pods/components/matter/subjects/db-monitor-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Zj1emw14",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"row\",\"dbMonitorA\"]]],null,{\"statements\":[[0,\"  \"],[1,[25,[\"row\",\"dbMonitorA\",\"shortName\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[25,[\"row\",\"dbMonitorS\"]]],null,{\"statements\":[[0,\"(\"],[1,[25,[\"row\",\"dbMonitorS\"]],false],[0,\")\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-admin/pods/components/matter/subjects/db-monitor-cell/template.hbs"
    }
  });

  _exports.default = _default;
});