define("js2-admin/adapters/billed-invoice", ["exports", "js2-admin/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    pathForType: function pathForType(modelName) {
      return 'invoices/billed';
    }
  });

  _exports.default = _default;
});