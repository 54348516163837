define("js2-admin/pods/components/document-url/component", ["exports", "js2-admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    baseURI: _environment.default.APP.documentDownloadURL,
    extraClasses: null,
    text: false,
    showIcon: true,
    downloadURI: Ember.computed('id', 'subjectId', function () {
      return this.baseURI + this.id + '/download?token=' + this.get('session.data.authenticated.token') + '&subject_id=' + this.subjectId;
    }),
    suggestedFileName: 'download.php',
    tagName: '',
    actions: {
      markView: function markView() {
        if (this.get('markDocumentAsViewed')) {
          this.get('markDocumentAsViewed')();
        }
      }
    }
  });

  _exports.default = _default;
});