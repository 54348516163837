define("js2-admin/components/sc-sidebar", ["exports", "js-common/components/sc-sidebar"], function (_exports, _scSidebar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _scSidebar.default;
    }
  });
});