define("js2-admin/helpers/highlight-result", ["exports", "js-common/helpers/highlight-result"], function (_exports, _highlightResult) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _highlightResult.default;
    }
  });
  Object.defineProperty(_exports, "highlightResult", {
    enumerable: true,
    get: function get() {
      return _highlightResult.highlightResult;
    }
  });
});