define("js2-admin/utils/save-tags-on-subjects", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = saveTagsOnSubjects;

  function saveTagsOnSubjects(store, ajax, notify, subjects, tagsSelectedToAdd, tagsSelectedToRemove, callback) {
    var _this = this;

    var promises = [];

    var addTagsAjax = function addTagsAjax(subject) {
      var tagsToAdd = (tagsSelectedToAdd || []).filter(function (tag) {
        return Ember.isEmpty(subject.get('tags').findBy('id', +tag.id));
      });
      tagsToAdd.forEach(function (tag) {
        var modelName = 'subject-has-tag';
        var newTag = {
          tag: tag,
          subject: subject
        };
        newTag = store.createRecord(modelName, newTag);
        promises.pushObject(newTag.save().catch(function () {
          return _this.validationReport(newTag);
        }));
      });
    };

    subjects.forEach(addTagsAjax);

    var removeTagsAjax = function removeTagsAjax(subject) {
      var currentTags = subject.get('tags');
      var tagsToRemove = (tagsSelectedToRemove || []).filter(function (t) {
        return !Ember.isEmpty(currentTags.findBy('id', +t.id));
      });
      tagsToRemove.forEach(function (tag) {
        var tagList = subject.get('tags');
        var matchList = tagList.filterBy('id', +tag.id);
        matchList.forEach(function (parentTag) {
          promises.pushObject(ajax.del("subject_has_tags/".concat(parentTag.subject_has_tag_id)));
        });
      });
    };

    subjects.forEach(removeTagsAjax);

    if (promises.length === 0) {
      notify.success('No actions to perform');
      return;
    }

    Ember.RSVP.all(promises).then(callback);
  }
});