define("js2-admin/pods/error/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+A8SaSBK",
    "block": "{\"symbols\":[],\"statements\":[[5,\"sc-container\",[[13,\"class\",\"mt-5 text-center\"]],[[\"@width\"],[\"fluid\"]],{\"statements\":[[0,\"\\n  \"],[7,\"h1\"],[11,\"class\",\"mb-3\"],[9],[0,\"There was an error processing your request\"],[10],[0,\"\\n\\n  \"],[7,\"h4\"],[9],[0,\"Please report this error to the administrator\"],[10],[0,\"\\n\\n  \"],[7,\"p\"],[9],[0,\"\\n      We will work on fixing that right away.\\n  \"],[10],[0,\"\\n  \"],[7,\"p\"],[9],[0,\"\\n      It's possible that some data you requested is not accessible by your profile.\\n  \"],[10],[0,\"\\n  \"],[7,\"p\"],[9],[0,\"\\n      Meanwhile, you may \"],[7,\"a\"],[11,\"href\",\"/\"],[9],[0,\"return to dashboard\"],[10],[0,\".\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-admin/pods/error/template.hbs"
    }
  });

  _exports.default = _default;
});