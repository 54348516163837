define("js2-admin/pods/components/matters/notes-action/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nEOLrAhq",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"row\",\"specialInstructions\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\"],[12,\"id\",[29,\"concat\",[\"notes-popover-\",[25,[\"row\",\"id\"]]],null]],[9],[0,\"\\n    \"],[7,\"a\"],[11,\"role\",\"button\"],[9],[0,\"\\n      \"],[5,\"sc-icon\",[],[[\"@icon\",\"@iconSet\"],[\"commenting-o\",\"fa\"]]],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[5,\"sc-popover\",[],[[\"@triggerElement\",\"@title\"],[[29,\"concat\",[\"#notes-popover-\",[25,[\"row\",\"id\"]]],null],\"Notes\"]],{\"statements\":[[0,\"\\n    \"],[1,[25,[\"row\",\"specialInstructions\"]],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-admin/pods/components/matters/notes-action/template.hbs"
    }
  });

  _exports.default = _default;
});