define("js2-admin/components/social-link", ["exports", "js-admin-common/components/social-link"], function (_exports, _socialLink) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _socialLink.default;
    }
  });
});