define("js2-admin/pods/components/matter/db-memos/db-title/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * create a common tool to display DB names
   * toggles drive display for different circumstances
   */
  var _default = Ember.Component.extend({
    tagName: '',
    // what field is used to separate tags?
    separator: ' - ',
    // display the primary tag?
    primaryTag: true,
    // display special tags?
    specialTags: true,
    countMemos: null,
    displayCountry: Ember.computed('db.country', 'db.country.isUSA', 'db.name', function () {
      if (this.get('db.country')) {
        return !this.get('db.country.isUSA') && this.get('db.name').indexOf(this.get('db.country.name')) !== 0;
      }

      return false;
    })
  });

  _exports.default = _default;
});