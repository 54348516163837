define("js2-admin/pods/me/side-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ElTCAzDz",
    "block": "{\"symbols\":[],\"statements\":[[7,\"aside\"],[11,\"class\",\"main-sidebar\"],[9],[0,\"\\n  \"],[2,\" sidebar: style can be found in sidebar.less \"],[0,\"\\n  \"],[7,\"section\"],[11,\"class\",\"sidebar\"],[9],[0,\"\\n    \"],[2,\" sidebar menu: : style can be found in sidebar.less \"],[0,\"\\n    \"],[7,\"ul\"],[11,\"class\",\"sidebar-menu\"],[9],[0,\"\\n      \"],[7,\"li\"],[11,\"class\",\"header\"],[9],[0,\"Billing System\"],[10],[0,\"\\n\\n\\n\"],[4,\"active-link\",null,null,{\"statements\":[[4,\"link-to\",[\"me.notifications\"],null,{\"statements\":[[0,\"          \"],[1,[29,\"fa-icon\",[\"bell\"],null],false],[0,\" \"],[7,\"span\"],[9],[0,\"Notifications\"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[2,\" /.sidebar \"],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-admin/pods/me/side-bar/template.hbs"
    }
  });

  _exports.default = _default;
});