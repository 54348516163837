define("js2-admin/pods/components/forms/document/multi-create-update/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uzfycv3W",
    "block": "{\"symbols\":[\"@models\",\"@modelDefaults\",\"@origin\",\"&default\"],\"statements\":[[15,4,[[29,\"hash\",null,[[\"forms\",\"controls\"],[[29,\"component\",[\"forms/document/multi-create-update/forms\"],[[\"origin\",\"modelDefaults\",\"models\",\"files\",\"setFiles\",\"formManager\",\"onRemove\",\"totalUploadProgress\"],[[24,3,[]],[24,2,[]],[24,1,[]],[24,0,[\"_files\"]],[29,\"action\",[[24,0,[]],[24,0,[\"setFiles\"]]],null],[24,0,[]],[29,\"action\",[[24,0,[]],[24,0,[\"removeOne\"]]],null],[24,0,[\"totalUploadProgress\"]]]]],[29,\"component\",[\"forms/document/multi-create-update/controls\"],[[\"save\",\"cancel\",\"isSaving\"],[[29,\"action\",[[24,0,[]],[24,0,[\"save\"]]],null],[29,\"action\",[[24,0,[]],[24,0,[\"cancel\"]]],null],[24,0,[\"_saveAllTask\",\"isRunning\"]]]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-admin/pods/components/forms/document/multi-create-update/template.hbs"
    }
  });

  _exports.default = _default;
});