define("js2-admin/components/analytic-widget/social-media", ["exports", "js-common/components/analytic-widget/social-media"], function (_exports, _socialMedia) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _socialMedia.default;
    }
  });
});