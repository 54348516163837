define("js2-admin/pods/matter/documents/list-search/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "de8ePcq8",
    "block": "{\"symbols\":[],\"statements\":[[5,\"document-list-search\",[],[[\"@matterId\",\"@documentTypes\",\"@subjects\",\"@documents\",\"@query\",\"@terms\",\"@docName\",\"@selectedSubject\",\"@getReport\"],[[25,[\"currentMatter\",\"id\"]],[25,[\"model\",\"documentTypes\"]],[25,[\"model\",\"subjects\"]],[23,\"documents\"],[29,\"readonly\",[[25,[\"query\"]]],null],[29,\"readonly\",[[25,[\"terms\"]]],null],[29,\"readonly\",[[25,[\"docName\"]]],null],[29,\"readonly\",[[25,[\"selectedSubject\"]]],null],[29,\"route-action\",[\"getReport\"],null]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-admin/pods/matter/documents/list-search/template.hbs"
    }
  });

  _exports.default = _default;
});