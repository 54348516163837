define("js2-admin/models/resource-has-communication", ["exports", "js-common/models/resource-has-communication"], function (_exports, _resourceHasCommunication) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _resourceHasCommunication.default.extend({});

  _exports.default = _default;
});