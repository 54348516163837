define("js2-admin/pods/components/documents/table-content/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CNO8ikQ+",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col-sm-3\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"btn-group\"],[11,\"role\",\"group\"],[9],[0,\"\\n            \"],[7,\"button\"],[11,\"class\",\"btn btn-default btn-xs\"],[9],[0,\"\\n                All\\n            \"],[3,\"action\",[[24,0,[]],[29,\"action\",[[24,0,[]],[24,0,[\"contentParams\",\"selectAllDocuments\"]]],null]]],[10],[0,\"\\n            \"],[7,\"button\"],[11,\"class\",\"btn btn-default btn-xs\"],[9],[0,\"\\n                None\\n            \"],[3,\"action\",[[24,0,[]],[29,\"action\",[[24,0,[]],[24,0,[\"contentParams\",\"deselectAllDocuments\"]]],null]]],[10],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col-sm-9\"],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[2,\"Done this way since large tables don't show the loading icon\"],[0,\"\\n\"],[4,\"if\",[[25,[\"loading\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"table-loading-overlay container\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"table-loading-inner row\"],[9],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"vertical-center col-xs-12\"],[9],[0,\"\\n                \"],[7,\"div\"],[11,\"class\",\"text-center col-xs-12\"],[9],[0,\"\\n                    \"],[7,\"i\"],[11,\"class\",\"fa fa-cog fa-spin fa-3x\"],[9],[10],[0,\"\\n                \"],[10],[0,\"\\n            \"],[10],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[15,1],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-admin/pods/components/documents/table-content/template.hbs"
    }
  });

  _exports.default = _default;
});