define("js2-admin/pods/preview/timeline-events/route", ["exports", "js-common/mixins/crud/error"], function (_exports, _error) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_error.default, {
    subjectId: null,
    queryParams: {
      matterId: {
        refreshModel: false
      }
    },
    model: function model(params, transition) {
      var _this = this;

      if (params.subject_id) {
        this.set('subjectId', params.subject_id);
      } else {
        return null;
      }

      var subjectPromise = this.store.queryRecord('subject', {
        resource_id: this.subjectId,
        with: 'timeline_events'
      });
      return subjectPromise.then(function (subject) {
        return Ember.RSVP.hash({
          documentId: transition.params.preview.document_id,
          subjectId: _this.subjectId,
          subject: subject,
          events: _this.store.query('timeline-event', {
            resource_id: subject.get('timelineEvents').map(function (e) {
              return e.get('id');
            }),
            with: 'documents'
          }),
          eventTypes: _this.store.findAll('timeline-event-type').then(function (eventTypes) {
            return eventTypes.sortBy('name');
          }),
          matter: _this.store.findRecord('matter', params.matterId)
        });
      });
    },
    actions: {
      fetch: function fetch() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});