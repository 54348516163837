define("js2-admin/pods/components/matter/monitoring/base-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "M9bx/WRd",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"column\",\"cellComponent\"]]],null,{\"statements\":[[0,\"  \"],[1,[29,\"component\",[[25,[\"column\",\"cellComponent\"]]],[[\"tableActions\",\"extra\",\"table\",\"column\",\"row\",\"value\",\"rawValue\"],[[25,[\"tableActions\"]],[25,[\"extra\"]],[25,[\"table\"]],[25,[\"column\"]],[25,[\"row\"]],[25,[\"value\"]],[25,[\"rawValue\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[29,\"subject-field-read\",null,[[\"currentMatterFieldValue\",\"subject\",\"data\",\"matterFieldFamilies\",\"sortedMatterFields\",\"isTable\"],[[25,[\"column\",\"tpColumn\",\"apiInteractionName\"]],[25,[\"row\",\"content\"]],[25,[\"analyticsData\"]],[25,[\"extra\",\"matterFieldFamilies\"]],[25,[\"extra\",\"matterFields\"]],true]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-admin/pods/components/matter/monitoring/base-cell/template.hbs"
    }
  });

  _exports.default = _default;
});