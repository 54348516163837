define("js2-admin/pods/components/matter/subjects/base-cell/component", ["exports", "ember-light-table/components/cells/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({});

  _exports.default = _default;
});