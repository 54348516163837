define("js2-admin/pods/matter/workflows/edit/route", ["exports", "js-admin-common/mixins/workflows-edit-route"], function (_exports, _workflowsEditRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_workflowsEditRoute.default, {});

  _exports.default = _default;
});