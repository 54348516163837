define("js2-admin/components/note-form", ["exports", "js-admin-common/components/note-form"], function (_exports, _noteForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _noteForm.default;
    }
  });
});