define("js2-admin/pods/matter/subjects/info/workflow/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8CNYU7jc",
    "block": "{\"symbols\":[\"LG\",\"workflow\"],\"statements\":[[5,\"sc-container\",[],[[\"@width\"],[\"full\"]],{\"statements\":[[0,\"\\n  \"],[5,\"sc-list-group\",[],[[\"@type\",\"@size\"],[\"inner\",\"lg\"]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[25,[\"model\",\"workflows\"]]],null,{\"statements\":[[0,\"      \"],[6,[24,1,[\"item\"]],[[13,\"class\",\"px-0\"]],[[],[]],{\"statements\":[[0,\"\\n        \"],[1,[29,\"matter/subjects/workflow-card\",null,[[\"workflow\",\"employees\",\"workflowStatus\",\"saveChanges\"],[[24,2,[]],[25,[\"model\",\"employees\"]],[25,[\"model\",\"workflowStatus\"]],[29,\"action\",[[24,0,[]],\"saveWorkflow\",[24,2,[]]],null]]]],false],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"  \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-admin/pods/matter/subjects/info/workflow/template.hbs"
    }
  });

  _exports.default = _default;
});