define("js2-admin/pods/components/forms/document/create-update/controls/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5fhwuhOL",
    "block": "{\"symbols\":[\"@cancel\",\"@options\",\"@save\",\"@isSaving\"],\"statements\":[[7,\"div\"],[11,\"class\",\"forms__document__create-update__controls\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"d-flex justify-content-end\"],[9],[0,\"\\n\"],[4,\"unless\",[[24,2,[\"hideCancel\"]]],null,{\"statements\":[[0,\"      \"],[5,\"sc-button\",[],[[\"@label\",\"@color\",\"@type\",\"@onClick\"],[\"Cancel\",\"secondary\",\"contents\",[29,\"action\",[[24,0,[]],[24,1,[]]],null]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[5,\"sc-button\",[],[[\"@label\",\"@color\",\"@onClick\",\"@isRunning\",\"@isRunningLabel\"],[[29,\"or\",[[24,2,[\"saveLabel\"]],\"Save\"],null],\"primary\",[29,\"action\",[[24,0,[]],[24,3,[]]],null],[24,4,[]],\"Saving\"]]],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-admin/pods/components/forms/document/create-update/controls/template.hbs"
    }
  });

  _exports.default = _default;
});