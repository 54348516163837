define("js2-admin/models/db", ["exports", "js-common/models/db"], function (_exports, _db) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _db.default.extend({});

  _exports.default = _default;
});