define("js2-admin/models/case-tag", ["exports", "js-common/models/case-tag"], function (_exports, _caseTag) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _caseTag.default.extend({});

  _exports.default = _default;
});