define("js2-admin/pods/me/notifications/route", ["exports", "js-admin-common/mixins/notifications-route"], function (_exports, _notificationsRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_notificationsRoute.default, {});

  _exports.default = _default;
});