define("js2-admin/pods/components/documents/table-no-data/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "U9lqutWA",
    "block": "{\"symbols\":[],\"statements\":[[7,\"p\"],[11,\"class\",\"text-center\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"isGrouped\"]]],null,{\"statements\":[[0,\"      Please select a group from the left side list in order to show documents.\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      There are no documents related to this case.\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-admin/pods/components/documents/table-no-data/template.hbs"
    }
  });

  _exports.default = _default;
});