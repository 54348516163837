define("js2-admin/pods/components/smm/name-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "j1hounAr",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"row\",\"post\"]]],null,{\"statements\":[[0,\"    \"],[7,\"a\"],[12,\"href\",[25,[\"row\",\"post\",\"url\"]]],[12,\"title\",[25,[\"row\",\"post\",\"url\"]]],[11,\"target\",\"_blank\"],[11,\"rel\",\"noopener noreferrer\"],[11,\"class\",\"wrap-word\"],[9],[0,\"\\n        \"],[1,[23,\"value\"],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"a\"],[12,\"href\",[25,[\"row\",\"hyperlinkResource\",\"hyperlink\"]]],[12,\"title\",[25,[\"row\",\"hyperlinkResource\",\"hyperlink\"]]],[11,\"target\",\"_blank\"],[11,\"rel\",\"noopener noreferrer\"],[11,\"class\",\"wrap-word\"],[9],[0,\"\\n        \"],[1,[23,\"value\"],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-admin/pods/components/smm/name-cell/template.hbs"
    }
  });

  _exports.default = _default;
});