define("js2-admin/pods/preview/timeline-events/controller", ["exports", "jquery", "js-common/mixins/crud/error"], function (_exports, _jquery, _error2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_error2.default, {
    showNewEventForm: false,
    actions: {
      newEvent: function newEvent() {
        this.set('currentEventType', null);
        this.set('newEvent', this.store.createRecord('timeline-event', {
          subject: this.get('model.subject')
        }));
        this.set('showNewEventForm', true);
      },
      cancelNewEvent: function cancelNewEvent() {
        this.set('showNewEventForm', false);
      },
      saveNewEvent: function saveNewEvent() {
        var _this = this;

        var newEvent = this.newEvent;
        newEvent.set('matter', this.get('model.matter'));
        newEvent.set('timelineEventType', this.currentEventType);
        newEvent.save().then(function (result) {
          var subjectRelationship = _this.store.createRecord('resource-has-resource', {
            parentId: _this.get('model.subjectId'),
            childId: result.get('id')
          }).save();

          var documentRelationship = _this.store.createRecord('resource-has-resource', {
            parentId: _this.get('model.documentId'),
            childId: result.get('id')
          }).save();

          Ember.RSVP.Promise.all([subjectRelationship, documentRelationship]).then(function () {
            _this.notify.success('Event has been saved successfully');

            _this.set('showNewEventForm', false);

            _this.send('fetch');
          }, function () {
            this.validationReport(subjectRelationship);
          });
        }, function (_error) {
          _this.validationReport(newEvent);
        });
      },
      removeEvent: function removeEvent(event) {
        var _this2 = this;

        if (!window.confirm('Are you sure you want to remove this event?')) {
          return;
        }

        event.destroyRecord().then(function () {
          _this2.notify.success('Event has been deleted successfully');
        }, function (error) {
          _this2.validationReport(error);
        });
      },
      editEvent: function editEvent(event) {
        this.set('newEvent', event);
        this.set('currentEventType', event.get('timelineEventType'));
        this.set('showNewEventForm', true);
        Ember.run.next({}, function () {
          (0, _jquery.default)('html, body').animate({
            scrollTop: (0, _jquery.default)('#event-form').offset().top
          }, 500);
        });
      },
      selectionChange: function selectionChange(eventType) {
        this.set('currentEventType', eventType);
      }
    }
  });

  _exports.default = _default;
});