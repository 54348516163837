define("js2-admin/pods/components/document-url/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iyp7/hlh",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"log\",[\"document-url component has been deprecated, please start using <DocumentDownloadLink @id={{document.id}} />\"],null],false],[0,\"\\n\"],[7,\"a\"],[12,\"download\",[30,[[23,\"suggestedFilename\"]]]],[12,\"href\",[30,[[23,\"downloadURI\"]]]],[11,\"title\",\"Download the document\"],[12,\"class\",[23,\"extraClasses\"]],[12,\"onClick\",[29,\"action\",[[24,0,[]],\"markView\"],null]],[9],[0,\"\\n\"],[4,\"unless\",[[25,[\"text\"]]],null,{\"statements\":[[0,\"    \"],[1,[29,\"fa-icon\",[[25,[\"icon\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,[\"showIcon\"]]],null,{\"statements\":[[0,\"      \"],[1,[29,\"fa-icon\",[[25,[\"icon\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[1,[23,\"text\"],false],[0,\"\\n\"]],\"parameters\":[]}],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-admin/pods/components/document-url/template.hbs"
    }
  });

  _exports.default = _default;
});