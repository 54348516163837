define("js2-admin/pods/matter/updates/controller", ["exports", "ember-group-by"], function (_exports, _emberGroupBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    sortProperties: ['createdOn:desc'],
    sortedModel: Ember.computed.sort('model', 'sortProperties'),
    groupedUpdates: (0, _emberGroupBy.default)('sortedModel', 'createdDay')
  });

  _exports.default = _default;
});