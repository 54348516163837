define("js2-admin/pods/components/sc-external-layout/left-panel/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Afck3Wyb",
    "block": "{\"symbols\":[\"Col\",\"@col\"],\"statements\":[[4,\"let\",[[24,2,[]]],null,{\"statements\":[[6,[24,1,[]],[[13,\"class\",\"d-none d-md-flex sc-external-layout__left-panel\"]],[[\"@size\"],[\"xl-1 lg-2 md-2\"]],{\"statements\":[[0,\"\\n  \"],[7,\"video\"],[11,\"class\",\"background-video\"],[11,\"muted\",\"\"],[11,\"plays-inline\",\"\"],[11,\"data-video\",\"0\"],[9],[0,\"\\n    \"],[7,\"source\"],[11,\"src\",\"https://www.smithcarson.com/wp-content/themes/blankslate/images/HeaderVideoHD2.mp4\"],[11,\"type\",\"video/mp4\"],[9],[10],[0,\"\\n    \"],[7,\"img\"],[11,\"title\",\"Your browser does not support the video\"],[11,\"alt\",\"\"],[11,\"src\",\"https://www.smithcarson.com/wp-content/themes/blankslate/images/videoStatic.jpg\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"header\"],[9],[0,\"\\n    \"],[7,\"a\"],[11,\"href\",\"https://www.smithcarson.com/\"],[11,\"target\",\"_blank\"],[9],[0,\"\\n      \"],[5,\"sc-logo\",[],[[\"@logo\",\"@type\"],[\"app\",\"brandmark\"]]],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-admin/pods/components/sc-external-layout/left-panel/template.hbs"
    }
  });

  _exports.default = _default;
});