define("js2-admin/pods/components/app-nav-secondary/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "l1uD7EJY",
    "block": "{\"symbols\":[\"Navbar\",\"@color\",\"&default\"],\"statements\":[[4,\"if\",[[24,0,[\"session\",\"isAuthenticated\"]]],null,{\"statements\":[[0,\"  \"],[5,\"sc-sticky\",[],[[\"@stickTo\"],[\"top\"]],{\"statements\":[[0,\"\\n    \"],[5,\"sc-app-nav-secondary\",[],[[\"@color\",\"@toggleBreakpoint\"],[[24,2,[]],[24,0,[\"collapseOnBreakpoint\"]]]],{\"statements\":[[0,\"\\n      \"],[5,\"sc-container\",[],[[\"@width\"],[\"fluid\"]],{\"statements\":[[0,\"\\n        \"],[15,3,[[24,1,[]]]],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n\\n    \"]],\"parameters\":[1]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-admin/pods/components/app-nav-secondary/template.hbs"
    }
  });

  _exports.default = _default;
});