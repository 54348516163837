define("js2-admin/models/table-setting", ["exports", "@smith-carson/ui/models/table-setting"], function (_exports, _tableSetting) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _tableSetting.default.extend({});

  _exports.default = _default;
});