define("js2-admin/pods/components/documents/table-body/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6wj3/i2N",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[15,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-admin/pods/components/documents/table-body/template.hbs"
    }
  });

  _exports.default = _default;
});