define("js2-admin/pods/matter/subjects/info/db-memos/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DbWithMemo = Ember.Object.extend({
    db: null,
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    subjects: [],
    memos: [],
    isExpanded: false
  });

  var _default = Ember.Route.extend({
    subjectId: null,
    currentMatter: Ember.inject.service(),
    ajax: Ember.inject.service(),
    queryParams: {
      edit_memo_id: {
        refrehModel: true
      }
    },
    expandedDbs: [],
    beforeModel: function beforeModel(transition) {
      this.set('subjectId', transition.params['matter.subjects.info'].subject_id);
    },
    model: function model(params) {
      var _this = this;

      var currentMatterId = this.get('currentMatter').get('id');
      var subjectInfo = this.modelFor('matter.subjects.info');
      this.store.peekAll('db-memo').forEach(function (dbm) {
        return dbm.unloadRecord();
      });
      return Ember.RSVP.hash({
        subjectId: this.subjectId,
        employees: this.store.query('employee', {
          with_access_to_matter: this.get('currentMatter.id')
        }),
        db_memos: this.store.query('db-memo', {
          sort: '-resources:created_on',
          matter_id: currentMatterId,
          with: 'dbs,v_resource_has_resources,subjects',
          'v_resource_has_resources:child_id': this.subjectId,
          limit: 10000
        }),
        tags: this.ajax.request("db_tags/for-matter/".concat(currentMatterId), {
          data: {
            subject_id: this.subjectId
          }
        }).then(function (response) {
          _this.store.pushPayload(response);

          return response.db_tags.mapBy('id');
        }),
        databases: this.ajax.request("dbs/for-matter/".concat(currentMatterId), {
          data: {
            subject_id: this.subjectId
          }
        }).then(function (response) {
          _this.store.pushPayload(response);

          return response.dbs.map(function (db) {
            return DbWithMemo.create({
              db: _this.store.peekRecord('db', db.id),
              tagIds: db.tag_ids.split(','),
              subjectIds: db.subject_ids === null ? [] : db.subject_ids.split(','),
              memos: [],
              subjects: [],
              isExpanded: _this.get('expandedDbs').includes(db.id)
            });
          }).sort(_this.sortDbms.bind(_this));
        }),
        params: params
      });
    },
    customStringCompare: function customStringCompare(strA, strB) {
      var a = Ember.isNone(strA) ? '' : strA;
      var b = Ember.isNone(strB) ? '' : strB;
      return a.localeCompare(b);
    },
    sortDbms: function sortDbms(dbmA, dbmB) {
      var stateA = dbmA.get('db.state.name');
      var stateB = dbmB.get('db.state.name');
      var stateDiff = this.customStringCompare(stateA, stateB);
      var countyA = dbmA.get('db.county.name');
      var countyB = dbmB.get('db.county.name');

      if (stateDiff === 0) {
        var phraseA = dbmA.get('db.name');
        var phraseB = dbmB.get('db.name');

        if (Ember.isEmpty(countyA) !== true) {
          phraseA = countyA + phraseA;
        }

        if (Ember.isEmpty(countyB) !== true) {
          phraseB = countyB + phraseB;
        }

        return this.customStringCompare(phraseA, phraseB);
      }

      return stateDiff;
    },
    setupController: function setupController(controller, resolved) {
      this._super(controller, resolved);

      var subjectInfo = this.modelFor('matter.subjects.info');
      controller.set('allSubjects', this.modelFor('matter').subjects);
      controller.set('databasesWithMemos', resolved.databases.map(function (dbm) {
        dbm.set('memos', dbm.get('db.dbMemos'));
        return dbm;
      }).map(function (dbm) {
        dbm.set('subjects', [subjectInfo.subject.get('firstObject')]);
        return dbm;
      }));
      controller.set('tags', this.store.peekAll('db-tag').filter(function (t) {
        return resolved.tags.includes(t.id);
      }).map(function (t) {
        t.set('isExpanded', true);
        return t;
      }));
      controller.set('subject', subjectInfo.subject.get('firstObject'));
      controller.set('editItem', resolved.db_memos.findBy('id', resolved.params.edit_memo_id) || null);

      if (Ember.isPresent(controller.get('editItem'))) {
        controller.set('editItem.db.isExpanded', true);
      }

      Ember.run.later(function () {
        controller.scrollToEditMemo();
      }, 1000);
    },
    actions: {
      fetch: function fetch() {
        this.refresh();
      },
      toggleDbMemo: function toggleDbMemo(dbm, expanded) {
        if (expanded) {
          dbm.set('isExpanded', true);

          if (this.get('expandedDbs').includes(dbm.db.id)) {
            return;
          }

          this.get('expandedDbs').pushObject(dbm.db.id);
        } else {
          dbm.set('isExpanded', false);

          if (!this.get('expandedDbs').includes(dbm.db.id)) {
            return;
          }

          this.get('expandedDbs').removeObject(dbm.db.id);
        }
      }
    }
  });

  _exports.default = _default;
});