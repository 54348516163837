define("js2-admin/pods/components/forms/document/multi-create-update/forms/form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RnuXDrsH",
    "block": "{\"symbols\":[\"FormsDocumentCreateUpdate_ANGLE_0\",\"Form\",\"@modelDefaults\",\"@file\",\"@model\",\"@onRemove\",\"@isSaving\",\"@formOptions\",\"@registerWithFormManager\",\"@unregisterWithFormManager\"],\"statements\":[[4,\"let\",[[29,\"component\",[\"forms/document/create-update\"],null]],null,{\"statements\":[[6,[24,1,[]],[],[[\"@modelDefaults\",\"@file\",\"@model\",\"@onRemove\"],[[24,3,[]],[24,4,[]],[24,5,[]],[24,6,[]]]],{\"statements\":[[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"forms__document__multi-create-update__forms__form\"],[9],[0,\"\\n    \"],[5,\"sc-icon-button\",[[13,\"disabled\",[24,7,[]]],[13,\"class\",\"forms__document__multi-create-update__forms__form__close\"]],[[\"@icon\",\"@size\",\"@color\",\"@onClick\"],[\"cross-circle-filled\",\"lg\",\"dark\",[24,2,[\"remove\"]]]]],[0,\"\\n\\n    \"],[6,[24,2,[\"form\"]],[],[[\"@options\"],[[24,8,[]]]]],[0,\"\\n\\n\"],[0,\"    \"],[7,\"span\"],[9],[3,\"did-insert\",[[29,\"action\",[[24,0,[]],[24,9,[]],[24,2,[]]],null]]],[3,\"will-destroy\",[[29,\"action\",[[24,0,[]],[24,10,[]],[24,2,[]]],null]]],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[2]}],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-admin/pods/components/forms/document/multi-create-update/forms/form/template.hbs"
    }
  });

  _exports.default = _default;
});