define("js2-admin/helpers/margin-left", ["exports", "js-common/helpers/margin-left"], function (_exports, _marginLeft) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _marginLeft.default;
    }
  });
  Object.defineProperty(_exports, "marginLeft", {
    enumerable: true,
    get: function get() {
      return _marginLeft.marginLeft;
    }
  });
});