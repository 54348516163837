define("js2-admin/components/analytics-modules/validations-only", ["exports", "js-common/components/analytics-modules/validations-only"], function (_exports, _validationsOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _validationsOnly.default;
    }
  });
});