define("js2-admin/components/subject-custom-field-read", ["exports", "js-common/components/subject-custom-field-read"], function (_exports, _subjectCustomFieldRead) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _subjectCustomFieldRead.default;
    }
  });
});