define("js2-admin/pods/components/monitoring-document-edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EBFaYpoC",
    "block": "{\"symbols\":[\"fileToUpload\"],\"statements\":[[4,\"each\",[[25,[\"filesToUpload\"]]],null,{\"statements\":[[0,\"  \"],[1,[29,\"monitoring-document-edit/row\",null,[[\"resourceHasTagTask\",\"reports\",\"tags\",\"massAssigning\",\"fileToUpload\",\"documentTypes\",\"askForSubject\",\"matterId\",\"subjects\",\"currentSubject\",\"isNewlyUploaded\",\"remove\",\"caseEmployees\",\"caseTeams\"],[[25,[\"resourceHasTagTask\"]],[25,[\"reports\"]],[25,[\"tags\"]],[25,[\"massAssigning\"]],[24,1,[]],[25,[\"documentTypes\"]],[25,[\"askForSubject\"]],[25,[\"matter\",\"id\"]],[25,[\"subjects\"]],[25,[\"subject\"]],[25,[\"isNewlyUploaded\"]],[29,\"action\",[[24,0,[]],\"remove\"],null],[25,[\"caseEmployees\"]],[25,[\"caseTeams\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-admin/pods/components/monitoring-document-edit/template.hbs"
    }
  });

  _exports.default = _default;
});