define("js2-admin/pods/components/matter/subjects/base-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wjfdoxua",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"column\",\"cellComponent\"]]],null,{\"statements\":[[0,\"  \"],[1,[29,\"component\",[[25,[\"column\",\"cellComponent\"]]],[[\"tableActions\",\"table\",\"column\",\"row\",\"value\",\"rawValue\",\"appName\"],[[25,[\"tableActions\"]],[25,[\"table\"]],[25,[\"column\"]],[25,[\"row\"]],[25,[\"value\"]],[25,[\"rawValue\"]],\"js-admin\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[29,\"subject-field-read\",null,[[\"currentMatterFieldValue\",\"subject\",\"data\",\"matterFieldFamilies\",\"sortedMatterFields\",\"isTable\"],[[29,\"if\",[[25,[\"column\",\"tpColumn\",\"fieldName\"]],[29,\"underscore\",[[25,[\"column\",\"tpColumn\",\"fieldName\"]]],null],[25,[\"column\",\"tpColumn\",\"apiInteractionName\"]]],null],[25,[\"row\",\"content\"]],[25,[\"analyticsData\"]],[25,[\"extra\",\"matterFieldFamilies\"]],[25,[\"extra\",\"matterFields\"]],true]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-admin/pods/components/matter/subjects/base-cell/template.hbs"
    }
  });

  _exports.default = _default;
});