define("js2-admin/pods/matter/report-wizard/controller", ["exports", "moment", "sanitize-filename"], function (_exports, _moment, _sanitizeFilename) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    query_params: ['subject_ids'],
    subject_ids: [],
    reportFileName: Ember.computed('matter', function () {
      var matterName = this.get('matter.name');
      var date = (0, _moment.default)().format('MM-DD-YYYY');
      return (0, _sanitizeFilename.default)("".concat(matterName, " - SmithCarson Juror Profiles - ").concat(date));
    }),
    actions: {
      previewDocument: function previewDocument(docId) {
        this.transitionToRoute('preview', docId);
      }
    }
  });

  _exports.default = _default;
});