define("js2-admin/pods/components/matter/monitoring/base-cell/component", ["exports", "ember-light-table/components/cells/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    click: function click() {
      if (this.get('column.label') !== 'Actions') {
        var row = this.row;
        var table = this.table;
        var oldValue = row.get('content').get('showAll');
        table.get('rows').setEach('content.showAll', false);
        row.get('content').set('showAll', !oldValue);
      }
    }
  });

  _exports.default = _default;
});