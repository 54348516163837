define("js2-admin/pods/components/matter/db-memos/db-display/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service('router'),
    store: Ember.inject.service(),
    currentMatter: Ember.inject.service(),
    classNames: ['db-memo'],

    /**
     * the property passed in by the parent component
     * to render the db info
     */
    dbm: null,

    /**
     * @event
     * action name when opening a db
     */
    openDb: '',
    checkedSubjects: [],
    selectedSubjectsCount: Ember.computed('subjects.@each.isChecked', function () {
      return this.get('subjects').filterBy('isChecked').length;
    }),
    isSomethingPending: Ember.computed('dbm.memos.@each.isPending', function () {
      return this.get('dbm.memos').isAny('isPending', true);
    }),
    isSomethingBlank: Ember.computed('dbm.memos.@each.isBlank', function () {
      return this.get('dbm.memos').isAny('isBlank', true);
    }),
    actions: {
      openDb: function openDb(db) {
        this.sendAction('openDb', db);
      },
      toggleState: function toggleState() {
        this.sendAction('toggleDbMemo', this.get('dbm'), !this.dbm.isExpanded);
      },
      addMemo: function addMemo(db) {
        var _this = this;

        this.set('selectedEmployees', []);
        var dbMemo = this.get('store').createRecord('db-memo', {
          db: db,
          matter: this.get('currentMatter.matter'),
          body: '',
          createdOn: moment().format('YYYY-MM-DD HH:mm:ss')
        });
        dbMemo.save().then(function (memo) {
          memo.selectSubjects({
            subject_ids: [_this.get('subject.id')],
            memo_ids: [memo.get('id')]
          }).then(function () {
            if (_this.get('subject')) {
              memo.get('subjects.content').pushObject(_this.get('subject'));
            }

            _this.set('dbm.isExpanded', true);

            _this.dbm.memos.unshiftObject(memo);

            if (_this.attrs.editMemo) {
              _this.attrs.editMemo(memo);
            }
          });
        });
      }
    }
  });

  _exports.default = _default;
});