define("js2-admin/pods/matter/report-wizard/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentMatter: Ember.inject.service(),
    queryParams: {
      subject_ids: {
        refreshModel: true
      }
    },
    model: function model(params) {
      return Ember.RSVP.hash({
        matter: this.store.findRecord('matter', this.currentMatter.id),
        params: params,
        subjectTags: this.store.query('subject-tag', {
          matter_id: this.currentMatter.id
        })
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var matter = model.matter,
          subjectTags = model.subjectTags;
      controller.setProperties({
        matter: matter,
        subjectTags: subjectTags
      });
      var loc = this.currentMatter.matterFields.findBy('value', 'location');

      if (loc) {
        loc.get('options').then(function (options) {
          controller.set('defaultLocation', options.sortBy('weight').firstObject.value);
        });
      }
    }
  });

  _exports.default = _default;
});