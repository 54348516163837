define("js2-admin/models/matter-private-tag", ["exports", "js-common/models/matter-private-tag"], function (_exports, _matterPrivateTag) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _matterPrivateTag.default.extend();

  _exports.default = _default;
});