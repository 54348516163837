define("js2-admin/pods/components/sc-light-table/cells/subject/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "d2owQZL/",
    "block": "{\"symbols\":[\"@extra\",\"@row\",\"@column\",\"@rawValue\",\"@table\",\"@tableActions\"],\"statements\":[[4,\"if\",[[24,3,[\"cellComponent\"]]],null,{\"statements\":[[0,\"  \"],[1,[29,\"component\",[[24,3,[\"cellComponent\"]]],[[\"tableActions\",\"table\",\"column\",\"row\",\"value\",\"rawValue\",\"appName\"],[[24,6,[]],[24,5,[]],[24,3,[]],[24,2,[]],[24,0,[\"value\"]],[24,4,[]],\"js-admin\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[29,\"subject-field-read\",null,[[\"currentMatterFieldValue\",\"class\",\"subject\",\"data\",\"matterFieldFamilies\",\"sortedMatterFields\",\"isTable\"],[[24,3,[\"scColumn\",\"apiInteractionName\"]],\"trimmed-content\",[24,2,[\"content\"]],[24,0,[\"analyticsObject\",\"analyticsData\"]],[24,1,[\"matterFieldFamilies\"]],[24,1,[\"matterFields\"]],true]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-admin/pods/components/sc-light-table/cells/subject/template.hbs"
    }
  });

  _exports.default = _default;
});