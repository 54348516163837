define("js2-admin/pods/matter/navigation/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AlPzdOZa",
    "block": "{\"symbols\":[],\"statements\":[[7,\"aside\"],[11,\"class\",\"main-sidebar\"],[9],[0,\"\\n  \"],[2,\" sidebar: style can be found in sidebar.less \"],[0,\"\\n  \"],[7,\"section\"],[11,\"class\",\"sidebar\"],[9],[0,\"\\n    \"],[2,\" sidebar menu: : style can be found in sidebar.less \"],[0,\"\\n    \"],[7,\"ul\"],[11,\"class\",\"sidebar-menu\"],[9],[0,\"\\n      \"],[7,\"li\"],[11,\"class\",\"header\"],[9],[0,\"Case Menu\"],[10],[0,\"\\n      \"],[7,\"li\"],[9],[4,\"link-to\",[\"matter.subjects.list\",[29,\"query-params\",null,[[\"page\",\"sort\"],[\"1\",\"id\"]]]],null,{\"statements\":[[0,\"\\n          Subjects\"]],\"parameters\":[]},null],[10],[0,\"\\n      \"],[7,\"li\"],[9],[4,\"link-to\",[\"matter.documents\"],null,{\"statements\":[[0,\"Documents\"]],\"parameters\":[]},null],[10],[0,\"\\n      \"],[7,\"li\"],[9],[4,\"link-to\",[\"matter.report-wizard\",[29,\"query-params\",null,[[\"subject_ids\"],[[29,\"array\",null,null]]]]],null,{\"statements\":[[0,\"Report Wizard\"]],\"parameters\":[]},null],[10],[0,\"\\n      \"],[7,\"li\"],[9],[4,\"link-to\",[\"matter.detail.general.display\"],null,{\"statements\":[[0,\"Detail\"]],\"parameters\":[]},null],[10],[0,\"\\n      \"],[7,\"li\"],[9],[4,\"link-to\",[\"matter.billables\"],null,{\"statements\":[[0,\"Billing\"]],\"parameters\":[]},null],[10],[0,\"\\n      \"],[7,\"li\"],[9],[4,\"link-to\",[\"matter.expenses.display\"],null,{\"statements\":[[0,\"Expenses\"]],\"parameters\":[]},null],[10],[0,\"\\n      \"],[7,\"li\"],[9],[4,\"link-to\",[\"matter.admin\"],null,{\"statements\":[[0,\"Admin\"]],\"parameters\":[]},null],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[2,\" /.sidebar \"],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-admin/pods/matter/navigation/template.hbs"
    }
  });

  _exports.default = _default;
});