define("js2-admin/models/subject", ["exports", "js-common/models/subject", "ember-data"], function (_exports, _subject, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = _subject.default.extend({
    hasAccess: attr('boolean')
  });

  _exports.default = _default;
});