define("js2-admin/pods/components/forms/document/create-update/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZCO3CKiq",
    "block": "{\"symbols\":[\"Uploader\",\"@controlOptions\",\"@usedInWizard\",\"@formOptions\",\"@origin\",\"&default\"],\"statements\":[[5,\"sc-file-uploader\",[],[[\"@files\",\"@uploadURL\",\"@buildExtraParams\",\"@showUploadNotifications\",\"@validExtensions\",\"@onFileChange\"],[[29,\"array\",[[24,0,[\"file\"]]],null],[24,0,[\"uploadUrl\"]],[29,\"optional\",[[24,0,[\"buildExtraParams\"]]],null],false,[24,0,[\"validExtensions\"]],[29,\"action\",[[24,0,[]],[24,0,[\"setFile\"]]],null]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[26,6]],null,{\"statements\":[[0,\"    \"],[15,6,[[29,\"hash\",null,[[\"id\",\"form\",\"progressBar\",\"controls\",\"fileUploader\",\"save\",\"cancel\",\"remove\",\"model\"],[[24,0,[\"id\"]],[29,\"component\",[\"forms/document/create-update/form\"],[[\"origin\",\"model\",\"options\",\"remove\",\"isSaving\",\"uploader\",\"file\",\"usedInWizard\"],[[24,5,[]],[24,0,[\"_model\"]],[24,4,[]],[29,\"action\",[[24,0,[]],[24,0,[\"remove\"]]],null],[24,0,[\"_saveTask\",\"isRunning\"]],[24,1,[]],[24,0,[\"file\"]],[24,3,[]]]]],[29,\"component\",[\"forms/document/create-update/progress-bar\"],[[\"progress\"],[[24,1,[\"fileUploader\",\"progress\"]]]]],[29,\"component\",[\"forms/document/create-update/controls\"],[[\"isSaving\",\"options\",\"save\",\"cancel\"],[[24,0,[\"_saveTask\",\"isRunning\"]],[24,2,[]],[29,\"action\",[[24,0,[]],[24,0,[\"save\"]],[24,1,[\"fileUploader\",\"upload\"]]],null],[29,\"action\",[[24,0,[]],[24,0,[\"cancel\"]]],null]]]],[24,1,[\"fileUploader\"]],[29,\"action\",[[24,0,[]],[24,0,[\"save\"]],[24,1,[\"fileUploader\",\"upload\"]]],null],[24,0,[\"cancel\"]],[24,0,[\"remove\"]],[24,0,[\"_model\"]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[],\"parameters\":[]}]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-admin/pods/components/forms/document/create-update/template.hbs"
    }
  });

  _exports.default = _default;
});