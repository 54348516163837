define("js2-admin/models/matter-has-team", ["exports", "js-common/models/matter-has-team"], function (_exports, _matterHasTeam) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _matterHasTeam.default.extend({});

  _exports.default = _default;
});