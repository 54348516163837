define("js2-admin/pods/components/field-is-visible/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dM0MWVkQ",
    "block": "{\"symbols\":[\"s\",\"icon\"],\"statements\":[[7,\"span\"],[12,\"style\",[24,0,[\"colorStyle\"]]],[11,\"class\",\"m-r-5\"],[9],[0,\"\\n\"],[4,\"fa-stack\",null,null,{\"statements\":[[4,\"let\",[[24,0,[\"stackedIcon\"]]],null,{\"statements\":[[0,\"            \"],[1,[29,\"component\",[[29,\"-assert-implicit-component-helper-argument\",[[24,1,[\"stack-2x\"]],\"expected `s.stack-2x` to be a contextual component but found a string. Did you mean `(component s.stack-2x)`? ('js2-admin/pods/components/field-is-visible/template.hbs' @ L7:C14) \"],null],[24,2,[]]],null],false],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"        \"],[1,[29,\"component\",[[29,\"-assert-implicit-component-helper-argument\",[[24,1,[\"stack-1x\"]],\"expected `s.stack-1x` to be a contextual component but found a string. Did you mean `(component s.stack-1x)`? ('js2-admin/pods/components/field-is-visible/template.hbs' @ L9:C10) \"],null],[24,0,[\"icon\"]]],[[\"title\"],[[24,0,[\"title\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-admin/pods/components/field-is-visible/template.hbs"
    }
  });

  _exports.default = _default;
});