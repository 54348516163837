define("js2-admin/models/user-has-team", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    bill: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    billCopy: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    report: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    referredBy: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    whoHired: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    decisionMaker: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    team: _emberData.default.belongsTo('team', {
      async: false
    }),
    user: _emberData.default.belongsTo('user', {
      async: false
    })
  });

  _exports.default = _default;
});