define("js2-admin/pods/components/light-table/cells/link-to/component", ["exports", "@smith-carson/ui/pods/components/light-table/cells/link-to/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _component.default;
    }
  });
});