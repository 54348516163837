define("js2-admin/pods/components/subjects/publish-subjects/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MFktGprm",
    "block": "{\"symbols\":[\"@showModal\",\"@publishSubjects\",\"@cancel\",\"@selectedRows\"],\"statements\":[[5,\"sc-confirm\",[],[[\"@open\",\"@onConfirm\",\"@onCancel\",\"@confirmLabel\"],[[24,1,[]],[29,\"action\",[[24,0,[]],[24,2,[]]],null],[29,\"action\",[[24,0,[]],[24,3,[]]],null],\"Publish\"]],{\"statements\":[[0,\"\\n  Are you sure you want to publish\\n\"],[4,\"if\",[[29,\"gt\",[[24,4,[\"length\"]],1],null]],null,{\"statements\":[[0,\"    these subjects?\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    this subject?\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-admin/pods/components/subjects/publish-subjects/template.hbs"
    }
  });

  _exports.default = _default;
});