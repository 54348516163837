define("js2-admin/models/analytic-stat", ["exports", "js-common/models/analytic-stat"], function (_exports, _analyticStat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _analyticStat.default.extend({});

  _exports.default = _default;
});