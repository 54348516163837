define("js2-admin/pods/matter/route", ["exports", "js-common/helpers/is-orphan-matter-field"], function (_exports, _isOrphanMatterField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentMatter: Ember.inject.service(),
    session: Ember.inject.service(),
    lists: Ember.inject.service(),
    // load matter_id at the earliest possible moment for nested routes to reference
    beforeModel: function beforeModel(transition) {
      var _this = this;

      this._super(transition);

      var currentUserId = this.get('session.data.authenticated.id');
      var matter = this.currentMatter;
      var parentRoute = transition.params['matter'];
      var matterId = parentRoute.matter_id;
      (false && !(!Ember.isEmpty(matterId)) && Ember.assert('Could not load expected matter_id!', !Ember.isEmpty(matterId)));
      matter.set('id', matterId);
      return this.store.findRecord('matter', matterId, {
        reload: true
      }).then(function (m) {
        matter.set('matter', m);
      }, function (e) {
        transition.abort();

        if (!Ember.isEmpty(currentUserId)) {
          _this.transitionTo('unauthorized');
        }

        return Ember.RSVP.reject(e);
      });
    },
    model: function model(params) {
      var _this2 = this;

      var currentMatter = this.currentMatter;
      var currentUserId = this.get('session.data.authenticated.id');
      var matter = currentMatter.get('matter');
      matter.trackView().then(function (response) {
        if ('user_viewed_matters' in response) {
          _this2.store.pushPayload(response);
        }
      });
      return Ember.RSVP.hash({
        matter: currentMatter.get('matter'),
        matterFields: this.store.query('matter_field', {
          matter_id: params.matter_id
        }),
        matterHasCurrentUser: this.store.queryRecord('matter-has-user', {
          matter_id: params.matter_id,
          user_id: currentUserId,
          with: 'groups'
        }),
        phrases: this.store.query('field-phrase', {
          matter_id: ['NULL', params.matter_id]
        }),
        subjects: this.store.query('subject', {
          matter_id: this.get('currentMatter.matter.id')
        }),
        scPortalDashboardType: this.store.query('appl-config', {
          name: ['matters.default.sc_portal_dashboard_type', "matters.".concat(this.currentMatter.id, ".sc_portal_dashboard_type")]
        })
      }).then(function (response) {
        var listsToFetch = response.matterFields.filter(function (mf) {
          return Ember.isPresent(mf.get('listName'));
        }).mapBy('listName');
        var fieldLabels = response.matterFields.mapBy('display');
        response.phrases = response.phrases.filter(function (p) {
          return fieldLabels.includes(p.get('field'));
        });
        return Ember.RSVP.hash(Ember.assign(response, {
          lists: _this2.lists.fetchMultipleLists(listsToFetch)
        }));
      });
    },

    /**
     * save the open matter to currentMatter service
     *
     * @param controller
     * @param model
     */
    setupController: function setupController(controller, resolved) {
      var currentMatter = this.currentMatter;
      currentMatter.set('matter', resolved.matter);
      currentMatter.set('matterFields', resolved.matterFields);
      var socialMediaField = resolved.matterFields.findBy('value', 'social_media');

      if (Ember.isPresent(socialMediaField)) {
        socialMediaField.set('readonly', resolved.matter.get('overwriteSocialMedia') === 'Y');
      }

      controller.set('matterHasCurrentUser', resolved.matterHasCurrentUser); // remove some hard coded values from the list
      // since these have specific formatting on the page

      var matterFields = currentMatter.get('matterFields').filter(function (field) {
        var fieldName = field.get('value');
        return fieldName === 'last_name' || fieldName === 'first_name' || field.get('isViewableSubject');
      }).filter(function (mf) {
        return !Ember.isPresent(mf.get('mergeField')) || (0, _isOrphanMatterField.isOrphanMatterField)([mf, resolved.matterFields, 'isViewableSubject']);
      });

      var createColumn = function createColumn(field) {
        var camelizedValue = field.get('value').camelize();
        var operators = ['contains', 'not_contains', 'equal', 'not_equal', 'blank', 'not_blank', 'between', 'csv'];

        if (field.get('fieldType') === 'date') {
          operators = ['contains', 'blank', 'not_blank', 'between_dates'];
        }

        return Ember.Object.create({
          fieldType: field.get('fieldType'),
          displayName: field.get('display'),
          fieldName: camelizedValue,
          apiName: field.get('value').underscore(),
          order: i++,
          width: field.get('columnWidth').toString() + 'px',
          cellType: 'subject',
          enabledFilterOperators: operators
        });
      };

      var i = 0;
      currentMatter.set('subjectColumnsForFilter', currentMatter.get('matterFields').filter(function (field) {
        return !field.get('isViewableSubject');
      }).map(createColumn)); // sort matterFields by weight for property display on screen

      var matterFieldsSorted = matterFields.sortBy('weight');
      i = 0;
      currentMatter.set('subjectColumns', [].pushObjects(matterFieldsSorted.map(createColumn)));
      var matterFieldFamilies = this.getMatterFieldFamilies(currentMatter.get('matterFields'));
      currentMatter.set('matterFieldFamilies', matterFieldFamilies);

      var initialSubjects = controller._autoSuggestSubjectsTask.perform();

      controller.set('autoSuggestSubjects', initialSubjects);

      this._super(controller, resolved.matter);
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        var currentMatter = this.currentMatter;
        currentMatter.reset();
      }
    },
    getMatterFieldFamilies: function getMatterFieldFamilies(matterFields) {
      var result = {};
      matterFields.forEach(function (field) {
        var value = field.get('value');
        var parent = field.get('mergeField');

        if (Ember.isPresent(parent)) {
          // we are dealing with a child field
          if (result[parent]) {
            result[parent].push(field);
          } else {
            result[parent] = [field];
          }
        } else {
          // parent or single field
          if (!result[value]) {
            result[value] = [];
          }
        }
      });
      return result;
    },
    actions: {
      // duplicate so this action can be reached by nested routes
      refreshInfoRoute: function refreshInfoRoute() {
        this.refresh();
      },
      refreshModel: function refreshModel() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});