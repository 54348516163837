define("js2-admin/pods/components/subjects/send-multiple/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "F9VI4UPz",
    "block": "{\"symbols\":[\"Modal\",\"@showModal\",\"@selectedRows\"],\"statements\":[[5,\"sc-modal\",[],[[\"@size\",\"@open\",\"@onHide\"],[\"xl\",[24,2,[]],[29,\"action\",[[24,0,[]],\"cancel\"],null]]],{\"statements\":[[0,\"\\n  \"],[6,[24,1,[\"header\"]],[],[[\"@title\"],[\"Send Report to Contact\"]]],[0,\"\\n\\n  \"],[6,[24,1,[\"body\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"send-subjects-to-contacts\",[],[[\"@subjects\",\"@alertUsersContacts\",\"@alertUsersEmployees\",\"@closeModal\",\"@actionReceiver\"],[[24,3,[]],[24,0,[\"alertUsersContacts\"]],[24,0,[\"alertUsersEmployees\"]],[29,\"toggle\",[\"showModal\",[24,0,[]]],null],[23,\"sendSubjectToContact\"]]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[6,[24,1,[\"footer\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"sc-button\",[],[[\"@label\",\"@color\",\"@type\",\"@onClick\"],[\"Cancel\",\"secondary\",\"contents\",[29,\"action\",[[24,0,[]],\"cancel\"],null]]]],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"selectedRows\",\"length\"]]],null,{\"statements\":[[0,\"      \"],[5,\"sc-button\",[],[[\"@label\",\"@color\",\"@onClick\"],[\"Send\",\"primary\",[29,\"send\",[[25,[\"sendSubjectToContact\"]],\"sendSubjects\"],null]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-admin/pods/components/subjects/send-multiple/template.hbs"
    }
  });

  _exports.default = _default;
});