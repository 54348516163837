define("js2-admin/routes/application", ["exports", "moment", "js-common/mixins/secure-default-route-factory", "ember-simple-auth/mixins/application-route-mixin", "js-common/mixins/currently-loading-mixin", "js-common/mixins/token-route", "js2-admin/config/environment", "jquery"], function (_exports, _moment, _secureDefaultRouteFactory, _applicationRouteMixin, _currentlyLoadingMixin, _tokenRoute, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // install simple-auth for all routes
  Ember.Route.reopenClass(_secureDefaultRouteFactory.default);

  var _default = Ember.Route.extend(_applicationRouteMixin.default, _currentlyLoadingMixin.default, _tokenRoute.default, {
    session: Ember.inject.service(),
    moment: Ember.inject.service(),
    router: Ember.inject.service(),
    tm2url: _environment.default.tm2restDestination,
    beforeModel: function beforeModel() {
      // Find the browser timezone and set it globally
      this.moment.setTimeZone(_moment.default.tz.guess());
    },
    setupController: function setupController() {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      this._super(args);

      if (!this.session.data.authenticated.token) {
        /* eslint-disable-next-line ember/no-jquery */
        (0, _jquery.default)('#jsd-widget').css('visibility', 'hidden');
      } else {
        /* eslint-disable-next-line ember/no-jquery */
        (0, _jquery.default)('#jsd-widget').css('visibility', 'unset');
      }
    }
  });

  _exports.default = _default;
});