define("js2-admin/pods/components/documents/table-no-data/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isGrouped: Ember.computed('noDataParams.groupBy', function () {
      return this.get('noDataParams.groupBy') !== 'none';
    })
  });

  _exports.default = _default;
});