define("js2-admin/pods/components/matter/import-subjects/import-operations/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "btPOjC2E",
    "block": "{\"symbols\":[\"@failureMessage\",\"@showFailureMessage\",\"@isImportingSubjects\",\"@currentStep\",\"@atBottom\"],\"statements\":[[4,\"if\",[[29,\"and\",[[24,5,[]],[29,\"eq\",[[24,4,[]],1],null]],null]],null,{\"statements\":[[0,\"  \"],[5,\"sc-button\",[],[[\"@label\",\"@color\",\"@onClick\"],[\"Next\",\"primary\",[29,\"action\",[[24,0,[]],[25,[\"incStep\"]]],null]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\\n\"],[4,\"if\",[[29,\"gt\",[[24,4,[]],1],null]],null,{\"statements\":[[4,\"if\",[[29,\"lt\",[[24,4,[]],4],null]],null,{\"statements\":[[0,\"    \"],[5,\"sc-button\",[],[[\"@label\",\"@color\",\"@onClick\"],[\"Back\",\"secondary\",[29,\"action\",[[24,0,[]],[25,[\"decStep\"]]],null]]]],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[29,\"lt\",[[24,4,[]],3],null]],null,{\"statements\":[[0,\"    \"],[5,\"sc-button\",[],[[\"@label\",\"@color\",\"@onClick\"],[\"Next\",\"primary\",[29,\"action\",[[24,0,[]],[25,[\"incStep\"]]],null]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[4,\"if\",[[29,\"eq\",[[24,4,[]],3],null]],null,{\"statements\":[[0,\"  \"],[5,\"sc-button\",[],[[\"@label\",\"@color\",\"@onClick\",\"@icon\",\"@isRunning\"],[\"Import Subjects\",\"primary\",[29,\"action\",[[24,0,[]],[25,[\"importSubjects\"]]],null],\"check\",[24,3,[]]]]],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[29,\"eq\",[[24,4,[]],4],null]],null,{\"statements\":[[0,\"  \"],[5,\"sc-button\",[],[[\"@label\",\"@color\",\"@onClick\"],[\"Start Over\",\"secondary\",[29,\"action\",[[24,0,[]],[25,[\"incStep\"]]],null]]]],[0,\"\\n\"],[4,\"if\",[[24,2,[]]],null,{\"statements\":[[0,\"    \"],[5,\"sc-alert\",[[13,\"class\",\"mt-3\"]],[[\"@type\",\"@dismissible\"],[\"danger\",false]],{\"statements\":[[0,\"\\n      \"],[1,[24,1,[]],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-admin/pods/components/matter/import-subjects/import-operations/template.hbs"
    }
  });

  _exports.default = _default;
});