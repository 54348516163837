define("js2-admin/pods/matter/report-wizard/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fBGFeRCa",
    "block": "{\"symbols\":[],\"statements\":[[5,\"report-wizard\",[],[[\"@matter\",\"@onlyButton\",\"@reportName\",\"@previewDocument\",\"@selectedSubjectIds\",\"@origin\",\"@fileName\",\"@defaultSubjectFilters\",\"@includeDocumentsIndex\",\"@subjectTags\"],[[24,0,[\"matter\"]],false,\"subject_snapshot\",\"previewDocument\",[29,\"readonly\",[[24,0,[\"subject_ids\"]]],null],[29,\"if\",[[24,0,[\"matter\",\"useJsiAndScPortal\"]],\"tm\",\"js\"],null],[24,0,[\"reportFileName\"]],[29,\"hash\",null,[[\"location\"],[[25,[\"defaultLocation\"]]]]],true,[24,0,[\"subjectTags\"]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-admin/pods/matter/report-wizard/template.hbs"
    }
  });

  _exports.default = _default;
});