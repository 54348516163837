define("js2-admin/components/x-upload", ["exports", "js-common/components/x-upload"], function (_exports, _xUpload) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _xUpload.default;
    }
  });
});