define("js2-admin/pods/components/subjects/delete-subjects/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0hqPxuRb",
    "block": "{\"symbols\":[\"@showModal\",\"@deleteSubjects\",\"@cancel\",\"@selectedRows\"],\"statements\":[[5,\"sc-confirm\",[],[[\"@open\",\"@onConfirm\",\"@onCancel\",\"@confirmLabel\"],[[24,1,[]],[29,\"action\",[[24,0,[]],[24,2,[]]],null],[29,\"action\",[[24,0,[]],[24,3,[]]],null],\"Delete\"]],{\"statements\":[[0,\"\\n  \"],[7,\"div\"],[9],[0,\"\\n    Are you sure you want to delete\\n\"],[4,\"if\",[[29,\"gt\",[[24,4,[\"length\"]],1],null]],null,{\"statements\":[[0,\"      these subjects?\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      this subject?\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[9],[0,\"\\n    Note - All documents associated with \"],[4,\"if\",[[29,\"gt\",[[24,4,[\"length\"]],1],null]],null,{\"statements\":[[0,\"these subjects\"]],\"parameters\":[]},{\"statements\":[[0,\"this subject\"]],\"parameters\":[]}],[0,\" will also be deleted.\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-admin/pods/components/subjects/delete-subjects/template.hbs"
    }
  });

  _exports.default = _default;
});