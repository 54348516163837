define("js2-admin/pods/components/matter/subjects/new/profile-data-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jGOen2mS",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"form-group\"],[9],[0,\"\\n  \"],[7,\"label\"],[11,\"class\",\"col-sm-2 control-label\"],[9],[1,[25,[\"item\",\"display\"]],false],[0,\":\"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"col-sm-10\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"reRender\"]]],null,{\"statements\":[[0,\"      \"],[5,\"subject-edit-field\",[],[[\"@field\",\"@value\",\"@changeValue\",\"@searchEnabled\"],[[23,\"item\"],[29,\"get\",[[25,[\"model\"]],[25,[\"item\",\"name\"]]],null],[29,\"action\",[[24,0,[]],\"changeSubjectValue\"],null],true]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      Loading\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[29,\"and\",[[25,[\"item\",\"isEditable\"]],[29,\"not-eq\",[\"radio\",[25,[\"item\",\"type\"]]],null]],null]],null,{\"statements\":[[0,\"      \"],[1,[29,\"use-field-phrase\",null,[[\"phrases\",\"field\",\"usePhrase\"],[[25,[\"phrases\"]],[25,[\"item\"]],[29,\"action\",[[24,0,[]],\"usePhrase\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-admin/pods/components/matter/subjects/new/profile-data-row/template.hbs"
    }
  });

  _exports.default = _default;
});