define("js2-admin/models/document", ["exports", "js-common/mixins/document-model", "js2-admin/models/resource", "ember-data"], function (_exports, _documentModel, _resource, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr; // because Document is an extended resource and it's required for polymorphism we need to create it like this

  var _default = _resource.default.extend(_documentModel.default, {
    cantBundle: attr('number', {
      defaultValue: 0
    })
  });

  _exports.default = _default;
});