define("js2-admin/components/subjects/add-tags-multiple", ["exports", "js-admin-common/components/subjects/add-tags-multiple"], function (_exports, _addTagsMultiple) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _addTagsMultiple.default;
    }
  });
});