define("js2-admin/helpers/group-set", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.groupSet = groupSet;
  _exports.default = void 0;
  var currentGroup = null;

  function groupSet(params
  /*, hash */
  ) {
    var newVal = params[0];

    if (currentGroup !== newVal) {
      currentGroup = newVal;

      if (newVal === undefined || newVal === null) {
        newVal = 'None';
      }

      return Ember.String.htmlSafe('<h3>' + newVal + '</h3>');
    } else {
      return '';
    }
  }

  var _default = Ember.Helper.helper(groupSet);

  _exports.default = _default;
});