define("js2-admin/models/db-memo", ["exports", "js-common/models/db-memo"], function (_exports, _dbMemo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _dbMemo.default.extend({
    dbId: Ember.computed.alias('db.id'),
    dbName: Ember.computed.alias('db.name'),
    fullName: Ember.computed.alias('db.fullName')
  });

  _exports.default = _default;
});