define("js2-admin/pods/smm/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    router: Ember.inject.service(),
    modelName: 'scrape',
    currentRoute: 'smm',
    controllerName: 'smm',
    queryParams: {
      filterParams: {
        refreshModel: true
      },
      filter: {
        refreshModel: false
      }
    },
    model: function model(params) {
      return {
        params: params
      };
    },
    setupController: function setupController(controller, resolved) {
      controller.set('loadDataParams', {});
      var params = resolved.params;

      if (params.filter && !controller.get('loadedFromTableSettings')) {
        var jsonFilter = JSON.parse(params.filter);
        controller.set('currentControllerState', jsonFilter);
        controller.set('enableObservers', false);
        controller.fromTableSettingsState(controller.get('currentControllerState'));
        controller.updateColumnsFromTableSettingState(controller.get('currentControllerState'));
        controller.set('enableObservers', true);
      }

      this._super(controller, resolved); // controller.set('filter', JSON.stringify(controller.toTableSettingsState()));


      controller.set('loadedFromTableSettings', true);
      controller.setupTable(); // eslint-disable-next-line no-param-reassign

      controller.table.routeKey = this.router.urlFor(this.fullRouteName);
      controller.table.loadData.perform();
    },
    actions: {
      fetch: function fetch() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});