define("js2-admin/pods/components/smm/subject-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tZ89GmJp",
    "block": "{\"symbols\":[\"subject\"],\"statements\":[[4,\"let\",[[25,[\"row\",\"content\",\"subject\"]]],null,{\"statements\":[[0,\"    \"],[4,\"link-to\",[\"matter.subjects.info\",[24,1,[\"matter\",\"id\"]],[24,1,[\"id\"]]],null,{\"statements\":[[1,[24,1,[\"fullName\"]],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-admin/pods/components/smm/subject-cell/template.hbs"
    }
  });

  _exports.default = _default;
});