define("js2-admin/pods/components/modals/dashboard-layout/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
      Provides affordance (via modal dialog) for defining the Dashboard layout for
      the current `MatterRecord` as well as creating, defining, and deleting
      Dashboard layouts/templates in general.
  
      @module ModalsDashboardLayoutComponent
      @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    /**
        @property isSavingNewTemplate
        @type {Boolean}
     */
    isSavingNewTemplate: false,

    /**
        Stores new template name from `input` when creating a new template.
         @property newTemplateName
        @type {String}
     */
    newTemplateName: '',

    /**
        Prevent wrapper `div` on modal component.
         @property tagName
        @type {String}
     */
    tagName: '',

    /**
        Determines if currently selected layout/template can be deleted.
         @property canDeleteSelectedLayout
        @type {Boolean}
     */
    canDeleteSelectedLayout: Ember.computed('selectedLayout', function () {
      var selectedLayout = this.selectedLayout;
      return selectedLayout && !selectedLayout.locked;
    }),
    actions: {
      /**
          Resets `selectedLayout` value and closes modal on _Cancel_.
           @method cancelDashboardLayoutEdit
          @param {Object} modal BsModal component reference
       */
      cancelDashboardLayoutEdit: function cancelDashboardLayoutEdit(modal) {
        // Reset `selectedLayout`...
        this.onSelectTemplate(null); // ..and close modal

        modal.close();
      },

      /**
          Reset modal state when canceling _Save as new template_.
           @method cancelSaveAsNewTemplate
       */
      cancelSaveAsNewTemplate: function cancelSaveAsNewTemplate() {
        this.setProperties({
          isSavingNewTemplate: false,
          newTemplateName: null
        });
      },

      /**
          Handles opening the _Save as new template_ mini-form.
           @method openSaveAsNewTemplateForm
       */
      openSaveAsNewTemplateForm: function openSaveAsNewTemplateForm() {
        this.set('isSavingNewTemplate', true);
        Ember.run.next(function () {
          var input = document.querySelector('#new-template-name-input');

          if (input) {
            input.focus();
          }
        });
      },

      /**
          Handles creating a new `DashboardLayoutRecord` by passing the
          template name back to the invoking context via the
          `onSaveAsNewTemplate` handler.
           @method saveAsNewTemplate
       */
      saveAsNewTemplate: function saveAsNewTemplate() {
        var _this = this;

        this.onSaveAsNewTemplate(this.newTemplateName).then(function () {
          _this.set('isSavingNewTemplate', false);
        });
      },

      /**
          Handles updating the one-way bound `newTemplateName` property to
          support updating on `input` event (rather than default `change`
          event).
           @method updateTemplateName
          @param {String} `input` value
       */
      updateTemplateName: function updateTemplateName(value) {
        this.set('newTemplateName', value || '');
      }
    }
  });

  _exports.default = _default;
});