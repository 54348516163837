define("js2-admin/pods/preview/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "H7uMBVJU",
    "block": "{\"symbols\":[\"Row\",\"Col\",\"subject\"],\"statements\":[[5,\"sc-container\",[],[[\"@width\"],[\"fluid\"]],{\"statements\":[[0,\"\\n  \"],[6,[24,1,[]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[6,[24,2,[]],[],[[\"@size\"],[[29,\"if\",[[25,[\"hideRight\"]],\"12\",\"9\"],null]]],{\"statements\":[[0,\"\\n      \"],[5,\"sc-icon-button\",[[13,\"class\",\"float-right\"]],[[\"@onClick\",\"@size\",\"@icon\",\"@iconSet\"],[[29,\"toggle\",[\"hideRight\",[24,0,[]]],null],\"sm\",[29,\"if\",[[25,[\"hideRight\"]],\"compress\",\"expand\"],null],\"fa\"]]],[0,\"\\n      \"],[5,\"x-preview-document\",[],[[\"@document\",\"@searchable\"],[[25,[\"model\",\"document\"]],true]]],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[6,[24,2,[]],[[13,\"class\",[29,\"if\",[[25,[\"hideRight\"]],\"d-none\"],null]]],[[\"@size\"],[\"3\"]],{\"statements\":[[0,\"\\n      \"],[5,\"sc-power-select\",[],[[\"@placeholder\",\"@options\",\"@onchange\",\"@selected\"],[\"Please select a subject\",[25,[\"model\",\"subjectsInCase\"]],[29,\"action\",[[24,0,[]],\"selectionChange\"],null],[23,\"currentSubject\"]]],{\"statements\":[[0,\"\\n        \"],[1,[24,3,[\"fullName\"]],false],[0,\"\\n      \"]],\"parameters\":[3]}],[0,\"\\n\\n      \"],[1,[23,\"outlet\"],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[2]}],[0,\"\\n\"]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-admin/pods/preview/template.hbs"
    }
  });

  _exports.default = _default;
});