define("js2-admin/models/workflow-summary", ["exports", "js-common/models/workflow-summary"], function (_exports, _workflowSummary) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _workflowSummary.default.extend({});

  _exports.default = _default;
});