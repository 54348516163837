define("js2-admin/pods/components/smm/flag-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eV767h9B",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"value\"]]],null,{\"statements\":[[0,\"    \"],[5,\"sc-icon-button\",[[13,\"title\",[29,\"concat\",[\"Urgent \",[25,[\"value\"]],\"-day\"],null]]],[[\"@icon\",\"@iconSet\",\"@color\",\"@size\"],[\"flag\",\"fa\",\"danger\",\"sm\"]]],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[25,[\"row\",\"isForensic\"]]],null,{\"statements\":[[0,\"    \"],[5,\"sc-icon-button\",[[13,\"title\",\"Forensic Capture\"]],[[\"@icon\",\"@iconSet\",\"@color\",\"@size\"],[\"search-plus\",\"fa\",\"success\",\"sm\"]]],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[25,[\"row\",\"post\"]]],null,{\"statements\":[[0,\"    \"],[5,\"sc-badge\",[[13,\"title\",\"Relevant Post\"]],[[\"@color\"],[\"primary\"]],{\"statements\":[[0,\"\\n        1\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[25,[\"row\",\"isUpdated\"]]],null,{\"statements\":[[0,\"    \"],[5,\"sc-icon-button\",[[13,\"title\",\"Update Capture\"]],[[\"@icon\",\"@iconSet\",\"@color\",\"@size\"],[\"refresh\",\"fa\",\"info\",\"sm\"]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-admin/pods/components/smm/flag-cell/template.hbs"
    }
  });

  _exports.default = _default;
});