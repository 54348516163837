define("js2-admin/adapters/matter", ["exports", "js2-admin/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
      Provide Ember Data adapter support for the Matter model.
  
      @module adapters/matter
      @extends ApplicationAdapter
   */
  var _default = _application.default.extend({
    /**
        Supports passing the `allow_all` query parameter to the API when
        updating `MatterRecord`s.
         @method updateRecord
        @param {Store} store
        @param {Model} type
        @param {Snapshot} snapshot
        @returns {Promise}
     */
    updateRecord: function updateRecord(store, type, snapshot) {
      var adapterOptions = snapshot.adapterOptions;

      if (adapterOptions && adapterOptions.allowAll) {
        var data = {};
        var serializer = store.serializerFor(type.modelName);
        var url = this.buildURL(type.modelName, snapshot.id, snapshot, 'updateRecord');
        serializer.serializeIntoHash(data, type, snapshot);
        return this.ajax("".concat(url, "?allow_all=true"), 'PUT', {
          data: data
        });
      }

      return this._super(store, type, snapshot);
    }
  });

  _exports.default = _default;
});