define("js2-admin/pods/components/matter/db-memos/tag-link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7TlIar6M",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[29,\"eq\",[[25,[\"stateCategory\"]],[25,[\"tag\",\"parentId\"]]],null]],null,{\"statements\":[[0,\"  \"],[4,\"link-to\",[\"matter.db-memos.find-db\",[29,\"query-params\",null,[[\"tags\",\"tab\",\"state\"],[[29,\"array\",[[25,[\"tag\",\"id\"]]],null],\"states\",[25,[\"tag\",\"id\"]]]]]],[[\"target\"],[\"_blank\"]],{\"statements\":[[1,[29,\"fa-icon\",[\"database\"],null],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[4,\"link-to\",[\"matter.db-memos.find-db\",[29,\"query-params\",null,[[\"tags\"],[[29,\"array\",[[25,[\"tag\",\"id\"]]],null]]]]],[[\"target\"],[\"_blank\"]],{\"statements\":[[1,[29,\"fa-icon\",[\"database\"],null],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-admin/pods/components/matter/db-memos/tag-link/template.hbs"
    }
  });

  _exports.default = _default;
});