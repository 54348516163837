define("js2-admin/components/twbs-popover/content", ["exports", "ember-cli-bootstrap3-popover/components/twbs-popover/content"], function (_exports, _content) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _content.default;
    }
  });
});