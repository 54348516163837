define("js2-admin/helpers/target-blank-links", ["exports", "js-common/helpers/target-blank-links"], function (_exports, _targetBlankLinks) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _targetBlankLinks.default;
    }
  });
  Object.defineProperty(_exports, "targetBlankLinks", {
    enumerable: true,
    get: function get() {
      return _targetBlankLinks.targetBlankLinks;
    }
  });
});