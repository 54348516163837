define("js2-admin/pods/components/smm/status-cell/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: 'text-center',
    labelClass: Ember.computed('value', function () {
      var value = this.value;

      switch (value) {
        case 'New':
        case 'Pending':
        case 'New (previously failed)':
          return 'warning';

        case 'Canceled':
          return 'info';

        case 'Completed':
        case 'Finalized':
          return 'success';

        case 'Failed':
          return 'danger';

        default:
          return 'default';
      }
    })
  });

  _exports.default = _default;
});