define("js2-admin/mixins/report-info-route", ["exports", "js-common/mixins/crud/error"], function (_exports, _error) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_error.default, {
    reportCatalog: Ember.inject.service(),
    ajax: Ember.inject.service(),
    queryParams: {
      quickSearchField: {
        refreshModel: true
      },
      q: {
        refreshModel: true
      },
      perPage: {
        refreshModel: true
      },
      page: {
        refreshModel: true
      }
    },

    /**
     * load reprot and prep some data
     * @param params
     * @returns {*}
     */
    model: function model(params) {
      return Ember.RSVP.hash({
        tableData: this.makeRequest({
          type: this.apiName,
          style: 'tabular',
          per_page: params.perPage ? params.perPage : 50,
          page: params.page ? params.page : 1
        }),
        report: this.reportCatalog.getReport(this.apiName),
        perPage: params.perPage ? params.perPage : 50,
        page: params.page ? params.page : 1
      });
    },
    setupController: function setupController(controller, resolved) {
      this._super(controller, resolved);

      controller.set('perPageOptions', ['50', '100', '500', '1000', '2000']);
    },

    /**
    Makes a request to the API server.
    @method makeRequest
    @param {Object} data The request data
    @return {Ember.RSVP.Promise}
    @protected
    */
    makeRequest: function makeRequest(data) {
      return this.ajax.request('report_builder', {
        data: data
      });
    }
  });

  _exports.default = _default;
});