define("js2-admin/pods/matter/workflows/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CbOScy+j",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"matter/workflows/edit-page\",null,[[\"model\",\"editWorkflows\",\"matterFields\",\"employees\",\"workflowStatuses\",\"scroll\",\"save\",\"cancel\"],[[25,[\"model\"]],[25,[\"editWorkflows\"]],[25,[\"matterFields\"]],[25,[\"employees\"]],[25,[\"workflowStatuses\"]],true,[29,\"action\",[[24,0,[]],\"save\"],null],[29,\"action\",[[24,0,[]],\"cancel\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-admin/pods/matter/workflows/edit/template.hbs"
    }
  });

  _exports.default = _default;
});