define("js2-admin/pods/404/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4aoV1fPS",
    "block": "{\"symbols\":[],\"statements\":[[5,\"sc-container\",[[13,\"class\",\"mt-5 text-center\"]],[[\"@width\"],[\"fluid\"]],{\"statements\":[[0,\"\\n  \"],[7,\"h1\"],[11,\"class\",\"mb-3\"],[9],[0,\"404 - Page Not Found\"],[10],[0,\"\\n\\n  \"],[7,\"p\"],[9],[0,\"\\n    We could not find the page you were looking for.\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"p\"],[9],[0,\"\\n    Please click your browser \"],[7,\"a\"],[11,\"href\",\"javascript: history.back();\"],[9],[0,\"back\"],[10],[0,\" button to return to the previous page.\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-admin/pods/404/template.hbs"
    }
  });

  _exports.default = _default;
});