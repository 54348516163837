define("js2-admin/pods/components/matter-update/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * @property passiv-event model
     */
    event: null,

    /**
     * @property boolean if we want to show the date
     */
    withDate: true
  });

  _exports.default = _default;
});