define("js2-admin/pods/components/smm/matter-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PNHFVDUu",
    "block": "{\"symbols\":[\"matter\"],\"statements\":[[4,\"let\",[[25,[\"row\",\"content\",\"matter\"]]],null,{\"statements\":[[0,\"    \"],[4,\"link-to\",[\"matter.dashboard\",[24,1,[\"id\"]]],null,{\"statements\":[[1,[24,1,[\"tmCode\"]],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-admin/pods/components/smm/matter-cell/template.hbs"
    }
  });

  _exports.default = _default;
});