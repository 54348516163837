define("js2-admin/pods/matter/analytics/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XYiupw4k",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"matter-analytics-page\",null,[[\"matter\",\"plaintiff\",\"decedent\",\"parties_in_court_options\",\"user_status_options\",\"relationship_of_coplaintiff_options\"],[[25,[\"matter\"]],[25,[\"plaintiff\"]],[25,[\"decedent\"]],[25,[\"parties_in_court_options\"]],[25,[\"user_status_options\"]],[25,[\"relationship_of_coplaintiff_options\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-admin/pods/matter/analytics/template.hbs"
    }
  });

  _exports.default = _default;
});