define("js2-admin/session-stores/application", ["exports", "js2-admin/config/environment", "ember-simple-auth/session-stores/adaptive", "ember-simple-auth/session-stores/ephemeral"], function (_exports, _environment, _adaptive, _ephemeral) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var store;

  if (_environment.default.environment === 'testing') {
    store = _ephemeral.default.extend();
  } else {
    store = _adaptive.default.extend({
      localStorageKey: 'ember_simple_auth:js2'
    });
  }

  var _default = store;
  _exports.default = _default;
});