define("js2-admin/services/remote-handler", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    remoteError: function remoteError(e) {
      // TODO (TM-2010, TM-2011): extract if client state becomes out of sync with the server
      // or if network is down or other common problems
      return {
        e: e,
        outOfSync: false,
        offline: false
      };
    },
    bind: function bind(name) {
      return this[name].bind(this);
    }
  });

  _exports.default = _default;
});