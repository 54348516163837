define("js2-admin/pods/components/wizard-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    buttonTitle: null,
    handleClass: 'handle',
    previousMarkedItem: null,
    showSelectAll: false,
    showButton: false,
    showArrows: false,
    showFilter: false,
    showFieldsFilter: false,
    tagName: '',

    /**
        Filters are expected to be objects with following fields
         - `display` = what to display in placeholder
        - `apiInteractionName` = the field by which we filter
        - `value` = value to filter by
     */
    filters: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.setProperties({
        sortPropertiesForList: ['order:asc']
      });
    },
    orderProperties: Ember.computed('sortPropertiesForList.[]', function () {
      var properties = [];
      this.sortPropertiesForList.forEach(function (sortProperty) {
        properties.push(sortProperty.split(':')[0]);
      });
      return properties;
    }),

    /**
     * @property sortedList
     * @type {Array}
     */
    sortedList: Ember.computed.sort('list', 'sortPropertiesForList'),
    isArrowDisabled: Ember.computed('sortedList.@each.isMarked', function () {
      return this.sortedList.filterBy('isMarked', true).length !== 1;
    }),
    allAreMarked: Ember.computed('sortedList.@each.isMarked', function () {
      return this.sortedList.isEvery('isMarked', true);
    }),
    setItemOrder: function setItemOrder(item, newOrder) {
      this.orderProperties.forEach(function (property) {
        item.set(property, newOrder);
      });
    },
    swap: function swap(item, direction, sortedList) {
      var oldPosition = item.get('order');
      var newPosition = oldPosition + direction;
      var neighbor = sortedList.findBy('order', newPosition);
      this.setItemOrder(item, newPosition);
      this.setItemOrder(neighbor, oldPosition);
    },

    /**
     * Used when user clicks one list item, scrolls, then shift + clicks another
     * item ==> we mark every list item between the two selected items
     */
    markItemsInInterval: function markItemsInInterval(prev, current) {
      var prevOrder = prev.get('order');
      var currOrder = current.get('order');
      var mark = !current.get('isMarked');
      var start = prevOrder <= currOrder ? prevOrder : currOrder;
      var end = prevOrder <= currOrder ? currOrder : prevOrder;
      this.sortedList.forEach(function (item) {
        if (item.get('order') >= start && item.get('order') <= end) {
          item.set('isMarked', mark);
        }
      });
    },
    actions: {
      /**
       * Action called when the ember-sortable component
       * finished re-ordering a field inside the list of available fields
       * it just set a new order number based on the new order.
       *
       * @param sortedFields Array of Fields already ordered
       */
      reorderItems: function reorderItems(sortedItems) {
        var _this = this;

        var order = 1;
        /* eslint-disable-next-line no-plusplus */

        sortedItems.forEach(function (item) {
          return _this.setItemOrder(item, order++);
        });
      },
      toggleItemMark: function toggleItemMark(item) {
        item.set('isMarked', !item.get('isMarked'));
      },
      selectAll: function selectAll() {
        this.sortedList.forEach(function (item) {
          item.set('isMarked', true);
        });
      },
      deselectAll: function deselectAll() {
        this.sortedList.forEach(function (item) {
          item.set('isMarked', false);
        });
      },
      up: function up() {
        var sortedList = this.sortedList;
        var item = sortedList.findBy('isMarked', true);

        if (!this.isArrowDisabled && item.get('order') > 1) {
          this.swap(item, -1, sortedList);
        }
      },
      down: function down() {
        var sortedList = this.sortedList;
        var item = this.sortedList.findBy('isMarked', true);

        if (!this.isArrowDisabled && item.get('order') < sortedList.length) {
          this.swap(item, 1, sortedList);
        }
      },

      /**
          Sets all filter values to `null` and optionally makes a call to the
          fetch method to update the results.
           @method clearAllFilters
       */
      clearAllFilters: function clearAllFilters() {
        var filters = this.filters;
        filters.setEach('value', null);
        this.selectedFilters = [];
        this.send('applyFilter');
      },

      /**
          Sets an individual filter value to `null`.
           @method clearFilter
          @param {Object} filter
       */
      clearFilter: function clearFilter(filter) {
        filter.set('value', null);
        this.selectedFilters.removeObject(filter);
        this.send('applyFilter');
      },

      /**
          Builds params for filtered subjects request and calls passed-in
          fetch function.
           @method applyFilter
          @param {Array} filtersCopy Modified copy of filters passed from subjects-filter modal
       */
      applyFilter: function applyFilter(filtersCopy) {
        var params = {};
        var filters = this.filters,
            requestItemsWithFilterParams = this.requestItemsWithFilterParams; // Update `filters` state per changes in `SubjectsFilterModal`

        if (filtersCopy && Array.isArray(filtersCopy)) {
          filters.forEach(function (filter) {
            filter.set('value', filtersCopy.findBy('apiInteractionName', filter.apiInteractionName).get('value'));
          });
        }

        filters.forEach(function (filter) {
          switch (filter.apiInteractionName) {
            case 'subject_tags':
              {
                if (!Ember.isEmpty(filter.value)) {
                  // `hasAny` API lookup syntax
                  params.subject_tags = "*".concat(filter.value.mapBy('id').join(','));
                }

                break;
              }

            default:
              {
                if (!Ember.isEmpty(filter.value)) {
                  params[filter.apiInteractionName] = filter.value;
                }
              }
          }
        });

        if (typeof requestItemsWithFilterParams === 'function') {
          requestItemsWithFilterParams(params);
        }
      },
      itemSelected: function itemSelected(item, event) {
        if (event.shiftKey) {
          var prevItem = this.previousMarkedItem;
          this.markItemsInInterval(prevItem, item);
        }

        if (event.ctrlKey || event.metaKey) {
          item.set('isMarked', !item.get('isMarked'));
        }

        if (!event.shiftKey && !event.ctrlKey && !event.metaKey) {
          this.sortedList.forEach(function (listItem) {
            listItem.set('isMarked', false);
          });
          item.set('isMarked', true);
        }

        this.set('previousMarkedItem', item);
      }
    }
  });

  _exports.default = _default;
});