define("js2-admin/pods/components/avatar-cropper/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TOjC3vB+",
    "block": "{\"symbols\":[],\"statements\":[[4,\"unless\",[[25,[\"uploaded\"]]],null,{\"statements\":[[0,\"  \"],[1,[29,\"x-filefield\",null,[[\"selectedFiles\",\"multiple\"],[[25,[\"selectedImage\"]],false]]],false],[0,\"\\n\\n\"],[0,\"  \"],[7,\"canvas\"],[11,\"style\",\"display: none\"],[11,\"id\",\"avatar-canvas\"],[9],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"cropper\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"cropper-container\"],[11,\"style\",\"width:300px;\"],[9],[0,\"\\n          \"],[7,\"img\"],[12,\"src\",[23,\"image\"]],[11,\"width\",\"300px\"],[9],[10],[0,\"\\n      \"],[10],[0,\"\\n\"],[4,\"if\",[[25,[\"ready\"]]],null,{\"statements\":[[0,\"        \"],[7,\"button\"],[9],[0,\"Crop & Resize Avatar\"],[3,\"action\",[[24,0,[]],\"getCroppedAvatar\"]],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"cropped\"],[9],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"cropped-avatar\"],[9],[0,\"\\n              \"],[1,[23,\"croppedAvatar\"],false],[0,\"\\n            \"],[10],[0,\"\\n        \"],[10],[0,\"\\n\"],[4,\"if\",[[25,[\"croppedAvatar\"]]],null,{\"statements\":[[0,\"        \"],[7,\"button\"],[12,\"class\",[30,[[29,\"if\",[[25,[\"hideUploadButton\"]],\"hidden\"],null]]]],[9],[0,\"Upload Avatar\"],[3,\"action\",[[24,0,[]],\"uploadAvatar\"]],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"p\"],[11,\"class\",\"text-center\"],[9],[0,\"\\n        \"],[7,\"label\"],[9],[0,\"Current Avatar:\"],[10],[7,\"br\"],[9],[10],[0,\"\\n        \"],[7,\"img\"],[12,\"src\",[23,\"previewAvatar\"]],[9],[10],[7,\"br\"],[9],[10],[0,\"\\n        \"],[7,\"button\"],[9],[0,\"Change Avatar\"],[3,\"action\",[[24,0,[]],\"changeAvatar\"]],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-admin/pods/components/avatar-cropper/template.hbs"
    }
  });

  _exports.default = _default;
});