define("js2-admin/components/sc-analytic-widget/vertical-single-stacked-bar-with-legend", ["exports", "@smith-carson/ui/components/sc-analytic-widget/vertical-single-stacked-bar-with-legend"], function (_exports, _verticalSingleStackedBarWithLegend) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _verticalSingleStackedBarWithLegend.default;
    }
  });
});