define("js2-admin/utils/lineheight", ["exports", "ember-ckeditor/utils/lineheight"], function (_exports, _lineheight) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _lineheight.default;
    }
  });
});