define("js2-admin/pods/matter/info/quick-links/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ehu6k7qk",
    "block": "{\"symbols\":[\"quickLink\"],\"statements\":[[7,\"h2\"],[9],[0,\"\\n  Quick Links\\n\"],[10],[0,\"\\n\\n\"],[7,\"ul\"],[11,\"class\",\"list-group\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"model\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\"],[11,\"class\",\"list-group-item\"],[9],[0,\"\\n      \"],[7,\"a\"],[12,\"href\",[24,1,[\"url\"]]],[11,\"target\",\"_blank\"],[11,\"rel\",\"noopener noreferrer\"],[9],[0,\"\\n        \"],[1,[24,1,[\"name\"]],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-admin/pods/matter/info/quick-links/template.hbs"
    }
  });

  _exports.default = _default;
});