define("js2-admin/pods/components/smm/status-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "51+xP4r0",
    "block": "{\"symbols\":[],\"statements\":[[5,\"sc-badge\",[],[[\"@color\",\"@title\"],[[23,\"labelClass\"],[29,\"if\",[[29,\"eq\",[\"Completed\",[25,[\"value\"]]],null],[29,\"concat\",[\"Completed Date: \",[29,\"moment-format\",[[25,[\"row\",\"completedOn\"]],\"MM/DD/YYYY hh:mm a\"],null]],null]],null]]],{\"statements\":[[0,\"\\n    \"],[1,[23,\"value\"],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-admin/pods/components/smm/status-cell/template.hbs"
    }
  });

  _exports.default = _default;
});