define("js2-admin/pods/components/matter/workflows/flag-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IushAR6s",
    "block": "{\"symbols\":[\"@tableActions\"],\"statements\":[[7,\"div\"],[11,\"class\",\"text-center\"],[9],[0,\"\\n  \"],[7,\"a\"],[11,\"role\",\"button\"],[9],[0,\"\\n\"],[4,\"if\",[[29,\"eq\",[[25,[\"row\",\"flagStatus\"]],\"flagged\"],null]],null,{\"statements\":[[0,\"      \"],[5,\"sc-icon\",[],[[\"@icon\",\"@iconSet\",\"@color\"],[\"flag\",\"fa\",\"raspberry\"]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[5,\"sc-icon\",[],[[\"@icon\",\"@iconSet\"],[\"flag-o\",\"fa\"]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[3,\"action\",[[24,0,[]],[24,1,[\"editFlag\"]],[25,[\"row\"]]]],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-admin/pods/components/matter/workflows/flag-cell/template.hbs"
    }
  });

  _exports.default = _default;
});