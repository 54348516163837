define("js2-admin/pods/components/sc-light-table/cells/comment/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3QOP9Ylt",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"value\"]]],null,{\"statements\":[[0,\"  \"],[1,[29,\"nl-2br\",[[25,[\"value\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-admin/pods/components/sc-light-table/cells/comment/template.hbs"
    }
  });

  _exports.default = _default;
});