define("js2-admin/pods/components/documents/table-title/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WCZdfxbU",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"h3\"],[11,\"class\",\"box-title\"],[9],[7,\"i\"],[11,\"class\",\"ion ion-clipboard\"],[9],[10],[0,\" \"],[15,1],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"box-tools\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"pull-right\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"matter.document-new\"],[[\"class\"],[\"btn btn-primary btn-xs\"]],{\"statements\":[[0,\"        \"],[1,[29,\"fa-icon\",[\"plus\"],null],false],[0,\" Upload Documents\\n\"]],\"parameters\":[]},null],[0,\"\\n        \"],[7,\"button\"],[11,\"class\",\"btn btn-primary btn-xs\"],[9],[0,\"Edit Multiple\"],[3,\"action\",[[24,0,[]],[29,\"action\",[[24,0,[]],[24,0,[\"contentParams\",\"multiEdit\"]]],null]]],[10],[0,\"\\n        \"],[7,\"button\"],[11,\"class\",\"btn btn-primary btn-xs\"],[9],[1,[29,\"fa-icon\",[\"download\"],null],false],[0,\" Download Multiple Documents\"],[3,\"action\",[[24,0,[]],[29,\"action\",[[24,0,[]],[24,0,[\"contentParams\",\"multiDownload\"]]],null]]],[10],[0,\"\\n        \"],[7,\"button\"],[11,\"class\",\"btn btn-primary btn-xs\"],[9],[1,[29,\"fa-icon\",[\"trash-o\"],null],false],[0,\" Delete Multiple\"],[3,\"action\",[[24,0,[]],[29,\"action\",[[24,0,[]],[24,0,[\"contentParams\",\"multiDelete\"]]],null]]],[10],[0,\"\\n        \"],[1,[29,\"quick-print\",null,[[\"columnFields\",\"columnNames\",\"currentParams\",\"entity\",\"additionalClasses\"],[[25,[\"contentParams\",\"columnFields\"]],[25,[\"contentParams\",\"columnNames\"]],[25,[\"contentParams\",\"currentParams\"]],\"Document\",\"btn-xs\"]]],false],[0,\"\\n        \"],[4,\"link-to\",[\"matter.documents.list-search\"],[[\"class\"],[\"btn btn-default btn-xs\"]],{\"statements\":[[0,\"List Search\"]],\"parameters\":[]},null],[0,\"\\n        \"],[4,\"link-to\",[\"matter.documents.search\"],[[\"class\"],[\"btn btn-default btn-xs\"]],{\"statements\":[[0,\"Full text search\"]],\"parameters\":[]},null],[0,\"\\n    \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-admin/pods/components/documents/table-title/template.hbs"
    }
  });

  _exports.default = _default;
});