define("js2-admin/pods/matter/analytics/controller", ["exports", "js-admin-common/mixins/matter-analytics-controller"], function (_exports, _matterAnalyticsController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_matterAnalyticsController.default, {});

  _exports.default = _default;
});