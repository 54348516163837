define("js2-admin/pods/components/sc-light-table/cells/time-deadline/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aGTP2qWs",
    "block": "{\"symbols\":[\"deadline\",\"@column\"],\"statements\":[[4,\"if\",[[25,[\"value\"]]],null,{\"statements\":[[0,\"  \"],[1,[23,\"value\"],false],[0,\"\\n  h\\n\"]],\"parameters\":[]},null],[4,\"with\",[[29,\"get\",[[25,[\"row\"]],[29,\"compute\",[[29,\"action\",[[24,0,[]],\"replaceHoursWithDeadline\"],null],[24,2,[\"scColumn\",\"fieldName\"]]],null]],null]],null,{\"statements\":[[4,\"if\",[[24,1,[]]],null,{\"statements\":[[0,\"    \"],[4,\"if\",[[25,[\"value\"]]],null,{\"statements\":[[0,\"/\"]],\"parameters\":[]},null],[0,\"\\n    \"],[1,[24,1,[]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-admin/pods/components/sc-light-table/cells/time-deadline/template.hbs"
    }
  });

  _exports.default = _default;
});