define("js2-admin/models/matter-has-recognition", ["exports", "js-common/models/matter-has-recognition"], function (_exports, _matterHasRecognition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _matterHasRecognition.default.extend({});

  _exports.default = _default;
});