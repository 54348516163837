define("js2-admin/components/sc-track-table-scroll", ["exports", "@smith-carson/ui/components/sc-track-table-scroll"], function (_exports, _scTrackTableScroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _scTrackTableScroll.default;
    }
  });
});