define("js2-admin/pods/matter/info/notes/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mK+DWTX7",
    "block": "{\"symbols\":[\"Matternotesmatter-notes_ANGLE_0\"],\"statements\":[[4,\"let\",[[29,\"component\",[\"matter/notes/matter-notes\"],null]],null,{\"statements\":[[5,\"sc-container\",[[13,\"class\",\"pb-4\"]],[[\"@width\"],[\"fluid\"]],{\"statements\":[[0,\"\\n  \"],[6,[24,1,[]],[],[[\"@matter\"],[[25,[\"currentMatter\",\"matter\"]]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-admin/pods/matter/info/notes/template.hbs"
    }
  });

  _exports.default = _default;
});