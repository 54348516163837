define("js2-admin/serializers/matter", ["exports", "active-model-adapter"], function (_exports, _activeModelAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _activeModelAdapter.ActiveModelSerializer.extend({
    normalize: function normalize(typeClass, hash, prop) {
      // https://github.com/emberjs/rfcs/pull/57#issuecomment-121008369
      var foreignKey = hash.matter_type_id;

      if (foreignKey) {
        Object.assign(hash, {
          matter_type_id: foreignKey
        });
      }

      return this._super(typeClass, hash, prop);
    },
    serializeIntoHash: function serializeIntoHash(hash, typeClass, snapshot, options) {
      var _this = this;

      var normalizedRootKey = this.payloadKeyFromModelName(typeClass.modelName);
      var payload = this.serialize(snapshot, options);
      var newValues = snapshot.changedAttributes();
      var attrs = {};
      var data = {};
      snapshot.eachAttribute(function (attr, _options) {
        attrs[attr] = _options;
      });
      Object.keys(newValues).forEach(function (attribute) {
        var oldSnapshot = {
          attr: function attr(key) {
            return newValues[key][0];
          },
          type: snapshot.type
        };

        _this.serializeAttribute(oldSnapshot, data, attribute, attrs[attribute]);
      });
      payload.conflict_resolution_changed_attributes = data; // eslint-disable-next-line no-param-reassign

      hash[normalizedRootKey] = payload;
    },
    // http://emberjs.com/blog/2015/06/18/ember-data-1-13-released.html#toc_upgrade-guide
    isNewSerializerAPI: true
  });

  _exports.default = _default;
});