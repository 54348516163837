define("js2-admin/models/matter-column", ["exports", "js-common/models/matter-column"], function (_exports, _matterColumn) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _matterColumn.default.extend({});

  _exports.default = _default;
});