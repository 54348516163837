define("js2-admin/models/billed-invoice", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    status: _emberData.default.attr('string'),
    billDate: _emberData.default.attr('string'),
    billedAmount: _emberData.default.attr('number'),
    tmNum: _emberData.default.attr('string'),
    invoiceGroup: _emberData.default.belongsTo('invoice-group', {
      async: true
    }),
    invoiceBatch: _emberData.default.belongsTo('invoice-batch', {
      async: true
    }),
    matter: _emberData.default.belongsTo('matter', {
      async: true
    }),
    firm: _emberData.default.belongsTo('firm', {
      async: true
    }),
    invoiceSubs: _emberData.default.hasMany('invoice-sub', {
      async: true
    }),
    invoiceOutput: _emberData.default.belongsTo('invoice-output', {
      async: true
    }),
    clients: _emberData.default.hasMany('client', {
      async: true
    })
  });

  _exports.default = _default;
});