define("js2-admin/helpers/show-filesize", ["exports", "js-common/helpers/show-filesize"], function (_exports, _showFilesize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _showFilesize.default;
    }
  });
  Object.defineProperty(_exports, "formatFilesize", {
    enumerable: true,
    get: function get() {
      return _showFilesize.formatFilesize;
    }
  });
});