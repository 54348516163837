define("js2-admin/initializers/autosize-textarea", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  /* globals autosize */
  function initialize()
  /* container, application */
  {
    /**
     * Adding this code allow any textarea to have the ability
     * to grow depending on the text being entered so everything
     * is visible
     */
    Ember.TextArea.reopen({
      didInsertElement: function didInsertElement() {
        // initialize autosize http://www.jacklmoore.com/autosize/
        autosize(this.$()); // when the textarea is focused re-calculate heigh

        (0, _jquery.default)(this.$()).focus(function () {
          autosize.update(this);
        });
      }
    });
  }

  var _default = {
    name: 'autosize-textarea',
    initialize: initialize
  };
  _exports.default = _default;
});