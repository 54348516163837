define("js2-admin/models/subject-revision", ["exports", "js-common/models/subject-revision"], function (_exports, _subjectRevision) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _subjectRevision.default.extend({});

  _exports.default = _default;
});