define("js2-admin/components/power-select-multiple-with-create", ["exports", "ember-power-select-with-create/components/power-select-multiple-with-create"], function (_exports, _powerSelectMultipleWithCreate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _powerSelectMultipleWithCreate.default;
    }
  });
});