define("js2-admin/pods/matter/analytics/route", ["exports", "js-admin-common/mixins/matter-analytics-route"], function (_exports, _matterAnalyticsRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_matterAnalyticsRoute.default, {});

  _exports.default = _default;
});