define("js2-admin/pods/matter/updates/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentMatter: Ember.inject.service(),
    model: function model(params) {
      var currentMatter = this.currentMatter;
      params['matter_id'] = currentMatter.get('id');
      return this.store.query('passive-event', {
        matter_id: params['matter_id'],
        sort: '-created_on'
      });
    },
    afterModel: function afterModel(model, transition) {
      this.controllerFor('matter.updates').set('matter', this.modelFor('matter').matter);
    }
  });

  _exports.default = _default;
});