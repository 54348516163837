define("js2-admin/helpers/should-show-end-date", ["exports", "js-common/helpers/should-show-end-date"], function (_exports, _shouldShowEndDate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _shouldShowEndDate.default;
    }
  });
  Object.defineProperty(_exports, "shouldShowEndDate", {
    enumerable: true,
    get: function get() {
      return _shouldShowEndDate.shouldShowEndDate;
    }
  });
});