define("js2-admin/pods/components/sc-light-table/cells/workflow-status/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dAEE3wuo",
    "block": "{\"symbols\":[\"@tableActions\"],\"statements\":[[4,\"if\",[[25,[\"value\"]]],null,{\"statements\":[[4,\"if\",[[29,\"eq\",[[25,[\"value\"]],\"P\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"strong\"],[11,\"class\",\"progress\"],[9],[0,\"\\n      P\\n    \"],[10],[0,\"\\n    \"],[7,\"a\"],[11,\"role\",\"button\"],[11,\"class\",\"assign-button\"],[9],[0,\"\\n      C\\n    \"],[3,\"action\",[[24,0,[]],[24,1,[\"setStatus\"]],[25,[\"row\"]],[25,[\"column\",\"scColumn\"]],\"C\"]],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[29,\"eq\",[[25,[\"value\"]],\"S\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"strong\"],[11,\"class\",\"canceled\"],[9],[0,\"\\n      S\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"text-center\"],[9],[0,\"\\n      \"],[1,[23,\"value\"],false],[0,\"\\n    \"],[10],[0,\"\\n  \"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"a\"],[11,\"role\",\"button\"],[11,\"class\",\"assign-button\"],[9],[0,\"\\n    P\\n  \"],[3,\"action\",[[24,0,[]],[24,1,[\"setStatus\"]],[25,[\"row\"]],[25,[\"column\",\"scColumn\"]],\"P\"]],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-admin/pods/components/sc-light-table/cells/workflow-status/template.hbs"
    }
  });

  _exports.default = _default;
});