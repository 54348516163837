define("js2-admin/pods/search-documents/route", ["exports", "jquery", "js-admin-common/mixins/document-search-route"], function (_exports, _jquery, _documentSearchRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_documentSearchRoute.default, {
    notify: Ember.inject.service(),
    requiredPermissions: ['Edit Case'],
    setupController: function setupController(controller, resolved) {
      this._super(controller, resolved);
    },
    actions: {
      didTransition: function didTransition() {
        var self = this;
        Ember.run.schedule('afterRender', self, function () {
          (0, _jquery.default)('.conflicts-name').focus();
        });
      }
    }
  });

  _exports.default = _default;
});