define("js2-admin/serializers/subject-workflow", ["exports", "active-model-adapter"], function (_exports, _activeModelAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _activeModelAdapter.ActiveModelSerializer.extend({
    normalize: function normalize(modelClass, resourceHash, prop) {
      var keys = Object.keys(resourceHash);
      keys.forEach(function (key) {
        Ember.defineProperty(modelClass, 'attributes.' + key, Ember.computed(function (key, value) {
          if (value) {
            this.set(key, value);
            return value;
          } else {
            return this.get(key);
          }
        }));
      });

      if (this.normalizeHash && this.normalizeHash[prop]) {
        (false && !(false) && Ember.deprecate('`RESTSerializer.normalizeHash` has been deprecated. Please use `serializer.normalize` to modify the payload of single resources.', false, {
          id: 'ds.serializer.normalize-hash-deprecated',
          until: '3.0.0'
        }));
        this.normalizeHash[prop](resourceHash);
      }

      return this._super(modelClass, resourceHash);
    },

    /**
    Returns the resource's attributes formatted as a JSON-API "attributes object".
    http://jsonapi.org/format/#document-resource-object-attributes
    @method extractAttributes
    @param {Object} modelClass
    @param {Object} resourceHash
    @return {Object}
    */
    extractAttributes: function extractAttributes(modelClass, resourceHash) {
      var attributeKey;
      var attributes = {};
      var keys = Object.keys(resourceHash);
      keys.forEach(function (key) {
        attributeKey = key.camelize();
        attributes[attributeKey] = resourceHash[key];
      });
      return attributes;
    },
    // http://emberjs.com/blog/2015/06/18/ember-data-1-13-released.html#toc_upgrade-guide
    isNewSerializerAPI: true
  });

  _exports.default = _default;
});