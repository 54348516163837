define("js2-admin/helpers/value-from-map", ["exports", "js-common/helpers/value-from-map"], function (_exports, _valueFromMap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _valueFromMap.default;
    }
  });
  Object.defineProperty(_exports, "valueFromMap", {
    enumerable: true,
    get: function get() {
      return _valueFromMap.valueFromMap;
    }
  });
});