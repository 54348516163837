define("js2-admin/models/summary-case-statistic", ["exports", "js-common/models/summary-case-statistic"], function (_exports, _summaryCaseStatistic) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _summaryCaseStatistic.default.extend({});

  _exports.default = _default;
});