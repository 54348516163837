define("js2-admin/pods/components/app-nav-primary/component", ["exports", "js2-admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    currentUser: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    tm2url: _environment.default.APP.tm2AdminURL,

    /**
     * The breakpoint at which the navbar should collapse.
     * uses bootstrap4 breakpoint values
     * @see https://getbootstrap.com/docs/4.1/layout/overview/#responsive-breakpoints
     */
    collapseOnBreakpoint: 'sm',
    actions: {
      logout: function logout() {
        try {
          this.session.invalidate();
        } catch (e) {
          this.transitionTo('auth.login');
        }
      }
    }
  });

  _exports.default = _default;
});