define("js2-admin/serializers/receipt", ["exports", "active-model-adapter"], function (_exports, _activeModelAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _activeModelAdapter.ActiveModelSerializer.extend({
    normalize: function normalize(typeClass, hash, prop) {
      if (typeof hash.resource_id !== 'undefined' && Ember.isPresent(hash.resource_id)) {
        hash.id = hash.resource_id;
      }

      return this._super(typeClass, hash, prop);
    },
    // http://emberjs.com/blog/2015/06/18/ember-data-1-13-released.html#toc_upgrade-guide
    isNewSerializerAPI: true
  });

  _exports.default = _default;
});