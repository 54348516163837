define("js2-admin/components/sc-analytic-widget", ["exports", "@smith-carson/ui/components/sc-analytic-widget"], function (_exports, _scAnalyticWidget) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _scAnalyticWidget.default;
    }
  });
});