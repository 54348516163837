define("js2-admin/models/subject-addr", ["exports", "js-common/models/subject-addr"], function (_exports, _subjectAddr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _subjectAddr.default.extend({});

  _exports.default = _default;
});