define("js2-admin/pods/components/matter/subject-tags/published-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SBTVyj1a",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"row\",\"public\"]]],null,{\"statements\":[[0,\"    Yes: \"],[5,\"sc-button\",[],[[\"@label\",\"@icon\",\"@color\",\"@onClick\"],[\"Unpublish\",\"unlocked\",\"success\",[29,\"action\",[[24,0,[]],\"unpublish\",[25,[\"row\"]]],null]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    No: \"],[5,\"sc-button\",[],[[\"@label\",\"@icon\",\"@color\",\"@onClick\"],[\"Publish\",\"locked\",\"secondary\",[29,\"action\",[[24,0,[]],\"publish\",[25,[\"row\"]]],null]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-admin/pods/components/matter/subject-tags/published-cell/template.hbs"
    }
  });

  _exports.default = _default;
});