define("js2-admin/app", ["exports", "js2-admin/resolver", "ember-load-initializers", "js2-admin/config/environment"], function (_exports, _resolver, _emberLoadInitializers, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });

  if (_environment.default.environment === 'production') {
    Ember.Logger.debug = function () {};

    Ember.Logger.log = function () {};

    Ember.Logger.info = function () {};

    Ember.Logger.warn = function () {};
  }

  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
  var _default = App;
  _exports.default = _default;
});