define("js2-admin/pods/components/matter/subjects/new/profile-data/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    readIndex: 0,
    actions: {
      incrementReadIndex: function incrementReadIndex() {
        var readIndex = this.readIndex;
        this.set('readIndex', readIndex + 1);
      },
      getReadIndex: function getReadIndex() {
        return this.readIndex;
      }
    }
  });

  _exports.default = _default;
});