define("js2-admin/pods/auth/login/route", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    notify: Ember.inject.service(),
    queryParams: {
      message: {
        refreshModel: false
      },
      previous_url: {
        refreshModel: false
      }
    },
    model: function model(params) {
      return params;
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      if (model.message) {
        this.notify.warning(model.message);
      }
    }
  });

  _exports.default = _default;
});