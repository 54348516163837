define("js2-admin/mixins/report-info-controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    queryParams: ['quickSearchField', 'q', 'perPage', 'page', 'tableName'],
    q: '',
    quickSearch: '',
    perPage: '',
    page: '',
    // component name to use when rendering the table
    tableName: 'report/default-table',
    perPageChanged: Ember.observer('perPage', function () {
      this.set('page', 1);
    }),
    totalPages: Ember.computed('model.tableData.total_rows', 'perPage', function () {
      var totalRows = this.get('model.tableData.total_rows');
      var perPage = this.perPage;
      return Math.ceil(parseInt(totalRows) / parseInt(perPage));
    }),
    actions: {
      changePage: function changePage(newPage) {
        this.set('page', newPage);
      }
    }
  });

  _exports.default = _default;
});