define("js2-admin/helpers/permissions-check", ["exports", "js-common/helpers/permissions-check"], function (_exports, _permissionsCheck) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _permissionsCheck.default;
    }
  });
  Object.defineProperty(_exports, "permissionsCheck", {
    enumerable: true,
    get: function get() {
      return _permissionsCheck.permissionsCheck;
    }
  });
});