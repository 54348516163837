define("js2-admin/controllers/document-url-upgrade", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      markDocumentAsViewed: function markDocumentAsViewed() {//console.log('markDocumentAsViewed')
      }
    }
  });

  _exports.default = _default;
});