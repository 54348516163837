define("js2-admin/pods/components/matter/find-and-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fpu+9pHX",
    "block": "{\"symbols\":[\"matter\",\"result\",\"search\",\"@selectedMatters\"],\"statements\":[[5,\"sc-power-select\",[],[[\"@search\",\"@searchEnabled\",\"@onchange\",\"@searchPlaceholder\"],[[29,\"perform\",[[25,[\"searchMatters\"]]],null],true,[29,\"action\",[[24,0,[]],\"addMatter\"],null],\"Select the cases you need\"]],{\"statements\":[[0,\"\\n  \"],[1,[24,2,[\"truncatedCaseName\"]],false],[0,\"\\n\"]],\"parameters\":[2,3]}],[0,\"\\n\\n\"],[7,\"small\"],[9],[0,\"\\n  To pull specific cases, please type the File # or name in the box. Selected cases will be added to the list below.\\n\"],[10],[0,\"\\n\\n\"],[7,\"hr\"],[9],[10],[0,\"\\n\\n\"],[7,\"ul\"],[11,\"class\",\"list-group\"],[9],[0,\"\\n\"],[4,\"each\",[[24,4,[]]],null,{\"statements\":[[0,\"    \"],[7,\"li\"],[11,\"class\",\"list-group-item\"],[9],[0,\"\\n      \"],[1,[24,1,[\"tmCode\"]],false],[0,\" - \"],[1,[24,1,[\"name\"]],false],[0,\"\\n\\n      \"],[5,\"sc-icon-button\",[[13,\"class\",\"float-right\"]],[[\"@icon\",\"@iconSet\",\"@onClick\"],[\"trash\",\"fa\",[29,\"action\",[[24,0,[]],\"removeMatter\",[24,1,[]]],null]]]],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-admin/pods/components/matter/find-and-select/template.hbs"
    }
  });

  _exports.default = _default;
});