define("js2-admin/pods/matter/db-memos/for-db/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentMatter: Ember.inject.service(),
    lists: Ember.inject.service(),
    queryParams: {
      edit_id: {
        refresh: true
      }
    },

    /**
     * load subjects
     * @param params
     * @returns {*}
     */
    model: function model(params) {
      var currentMatterId = this.currentMatter.get('id');
      return Ember.RSVP.hash({
        db: this.store.queryRecord('db', {
          id: params.db_id
        }),
        db_has_tags: this.store.queryRecord('db-has-tag', {
          db_id: params.db_id,
          with: 'db_tags'
        }),
        files: this.store.query('db-has-file', {
          db_id: params.db_id,
          with: 'files'
        }),
        subjects: this.store.query('subject', {
          matter_id: currentMatterId
        }),
        memos: this.store.query('db-memo', {
          sort: '-resources:created_on',
          matter_id: currentMatterId,
          db_id: params.db_id,
          with: 'dbs,subjects'
        }),
        caseClassifications: this.lists.getListForGroup('case_classification'),
        employees: this.store.query('employee', {
          with_access_to_matter: this.get('currentMatter.id')
        }),
        params: params
      });
    },
    renderTemplate: function renderTemplate() {
      this.render({
        into: 'matter'
      });
    },

    /**
     *
     * @param controller
     * @param resolved
     */
    setupController: function setupController(controller, resolved) {
      this._super(controller, resolved);

      resolved.memos.setEach('isEditing', false);

      if (Ember.isPresent(resolved.params.edit_id) && resolved.params.edit_id === 'new') {
        controller.send('newMemo');
      } else {
        var memo = resolved.memos.findBy('id', resolved.params.edit_id);

        if (memo) {
          controller.send('editMemo', memo);
        }
      }

      controller.set('files', resolved.files.sortBy('file.name'));
      controller.set('columns', this.currentMatter.get('matterFields').filter(function (field) {
        switch (field.get('value')) {
          case 'alias':
          case 'ssan':
          case 'dob':
          case 'relationship':
            return true;

          default:
            return false;
        }
      }).map(function (field) {
        switch (field.get('value')) {
          case 'ssan':
            field.set('display', 'SSN');
            break;

          case 'dob':
            field.set('display', 'DOB');
            break;

          default:
        }

        return field;
      }).sortBy('weight'));
      var subjectsForAllMemos = [];
      resolved.subjects.forEach(function (s) {
        var found = false;
        resolved.memos.forEach(function (m) {
          m.get('subjects').forEach(function (sub) {
            if (sub.get('id') === s.get('id')) {
              found = true;
            }
          });
        });

        if (found) {
          subjectsForAllMemos.pushObject(s.get('id'));
        }
      });
      controller.set('subjectsForAllMemos', subjectsForAllMemos);
      controller.set('showInstructions', true);
      controller.set('employees', resolved.employees);
    },
    actions: {
      fetch: function fetch() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});