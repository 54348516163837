define("js2-admin/pods/components/spinner-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1XR62Jts",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,[\"isSpinning\"]]],null,{\"statements\":[[0,\"  \"],[1,[29,\"fa-icon\",[\"spinner\"],[[\"pulse\"],[\"true\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[15,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-admin/pods/components/spinner-button/template.hbs"
    }
  });

  _exports.default = _default;
});