define("js2-admin/models/v_matter_task", ["exports", "js-common/models/v-matter-task"], function (_exports, _vMatterTask) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _vMatterTask.default.extend({});

  _exports.default = _default;
});