define("js2-admin/pods/components/forms/document/create-update/progress-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nr7dYHrz",
    "block": "{\"symbols\":[\"ScFileUploaderProgressBar_ANGLE_0\",\"@progress\"],\"statements\":[[4,\"let\",[[29,\"component\",[\"sc-file-uploader/progress-bar\"],null]],null,{\"statements\":[[6,[24,1,[]],[],[[\"@progress\"],[[24,2,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-admin/pods/components/forms/document/create-update/progress-bar/template.hbs"
    }
  });

  _exports.default = _default;
});