define("js2-admin/pods/components/documents/table-title/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['box-header']
  });

  _exports.default = _default;
});