define("js2-admin/pods/components/matter/subjects/new/profile-data-row/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    reRender: true,
    actions: {
      usePhrase: function usePhrase(phrase) {
        var fieldPath = 'model.' + this.get('item.name');
        var breaks = '';

        for (var i = 0; i <= phrase.get('breaks'); i++) {
          breaks += '\n';
        }

        this.set('reRender', false);
        var previousVal = this.get(fieldPath);
        this.set(fieldPath, (Ember.isPresent(previousVal) ? previousVal + breaks : '') + phrase.get('phrase'));
        Ember.run.next(this, function () {
          this.set('reRender', true);
        });
      },
      changeSubjectValue: function changeSubjectValue(newValue, field) {
        this.set('model.' + field.get('name'), newValue);
      }
    }
  });

  _exports.default = _default;
});