define("js2-admin/controllers/application", ["exports", "js-common/mixins/token-controller"], function (_exports, _tokenController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_tokenController.default, {
    router: Ember.inject.service(),

    /**
        Provides a route specific HTML class name for scoping styles to
        individual pages (routes).
         @property routeClassName
        @type {String}
     */
    routeClassName: Ember.computed('router.currentRouteName', function () {
      var currentRouteName = this.get('router.currentRouteName');
      return "".concat(Ember.String.dasherize(currentRouteName.replace(/\./g, '-')), "-route");
    })
  });

  _exports.default = _default;
});