define("js2-admin/info/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    caseStatuses: ['Complete', 'In Progress', 'Not Started', 'Hold']
  };
  _exports.default = _default;
});