define("js2-admin/pods/components/field-is-visible/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MINIMAL_FIELDS = ['subject_type', 'first_name', 'last_name', 'member_id', 'location'];

  var _default = Ember.Component.extend({
    tagName: '',
    icon: Ember.computed('field.isViewableByClient', function () {
      var _this$getProperties = this.getProperties('field'),
          field = _this$getProperties.field;

      var _field$getProperties = field.getProperties('isViewableByClient'),
          isViewableByClient = _field$getProperties.isViewableByClient;

      if (isViewableByClient) {
        return 'unlock';
      }

      return 'lock';
    }),
    stackedIcon: Ember.computed('field.isViewableLimitedPublished', 'field.value', function () {
      var _this$getProperties2 = this.getProperties('field'),
          field = _this$getProperties2.field;

      var _field$getProperties2 = field.getProperties('value', 'isViewableLimitedPublished'),
          value = _field$getProperties2.value,
          isViewableLimitedPublished = _field$getProperties2.isViewableLimitedPublished;
      /*if (MINIMAL_FIELDS.indexOf(value) >= 0) {
          return 'circle-thin';
      }
      if (isViewableLimitedPublished) {
          return 'square-o';
      }*/
      // for next release


      return '';
    }),
    title: Ember.computed('field.isViewableLimitedPublished', function () {
      var _this$getProperties3 = this.getProperties('field'),
          field = _this$getProperties3.field;

      var _field$getProperties3 = field.getProperties('value', 'isViewableLimitedPublished', 'isViewableByClient'),
          value = _field$getProperties3.value,
          isViewableLimitedPublished = _field$getProperties3.isViewableLimitedPublished,
          isViewableByClient = _field$getProperties3.isViewableByClient;

      if (!isViewableByClient) {
        return 'Internal Field: Visible in admin only';
      }

      if (MINIMAL_FIELDS.indexOf(value) >= 0) {
        return 'Minimal Field: Visible in Portal Profile, Table, Report or Admin Report';
      }

      if (isViewableLimitedPublished) {
        return 'Limited Field: Visible in Portal Profile, Table, Report or Admin Report';
      }

      return 'Full Field: Visible in Portal Profile, Table, Report or Admin Report';
    }),
    colorStyle: Ember.computed('subject.published', 'subject.pending', 'subject.publishedOption', 'field.isViewableLimitedPublished', 'field.value', function () {
      var _this$getProperties4 = this.getProperties('subject', 'field'),
          field = _this$getProperties4.field,
          subject = _this$getProperties4.subject;

      var _field$getProperties4 = field.getProperties('value', 'isViewableLimitedPublished', 'isViewableByClient'),
          value = _field$getProperties4.value,
          isViewableLimitedPublished = _field$getProperties4.isViewableLimitedPublished,
          isViewableByClient = _field$getProperties4.isViewableByClient;

      var _subject$getPropertie = subject.getProperties('publishedOption', 'published', 'pending'),
          published = _subject$getPropertie.published,
          publishedOption = _subject$getPropertie.publishedOption,
          pending = _subject$getPropertie.pending;

      var publishedOptionCorrect = publishedOption || 'Not Published';
      var yellow = '#ffca1a';
      var green = '#00a65a';
      var purple = '#800080';
      var grey = 'black';
      var defaultStyle = "zoom: 0.65; ";

      if (!isViewableByClient) {
        return "color: ".concat(grey, "; ").concat(defaultStyle);
      } // All fields not published are purple


      if (!published) {
        return "color: ".concat(purple, "; ").concat(defaultStyle);
      } // If it's published check if pending (minimal or limited)


      if (pending) {
        if (MINIMAL_FIELDS.indexOf(value) >= 0 || isViewableLimitedPublished && publishedOptionCorrect.indexOf('Limited') >= 0) {
          return "color: ".concat(green, "; ").concat(defaultStyle);
        } else {
          return "color: ".concat(purple, "; ").concat(defaultStyle);
        }
      } // All published


      return "color: ".concat(green, "; ").concat(defaultStyle);
    })
  });

  _exports.default = _default;
});