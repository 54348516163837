define("js2-admin/pods/matter/workflows/edit/controller", ["exports", "js-admin-common/mixins/workflows-edit-controller"], function (_exports, _workflowsEditController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_workflowsEditController.default);

  _exports.default = _default;
});