define("js2-admin/pods/components/sc-light-table/cells/timeonly/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "x8xqO63M",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"log\",[[25,[\"valuePath\"]]],null],false],[0,\"\\n\"],[4,\"if\",[[25,[\"value\"]]],null,{\"statements\":[[0,\"  \"],[1,[23,\"value\"],false],[0,\"\\n  h\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-admin/pods/components/sc-light-table/cells/timeonly/template.hbs"
    }
  });

  _exports.default = _default;
});