define("js2-admin/pods/me/notifications/controller", ["exports", "js-admin-common/mixins/notifications-controller"], function (_exports, _notificationsController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_notificationsController.default, {});

  _exports.default = _default;
});