define("js2-admin/pods/components/matter-update/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "C8NqV4uZ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"passive-event\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"event-date\"],[9],[0,\"\\n    at \"],[1,[29,\"moment-format\",[[25,[\"event\",\"createdOn\"]],\"HH:mm:ss\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"event-description\"],[9],[0,\"\\n    \"],[1,[29,\"nl-2br\",[[29,\"trunc-text-by-lines\",[[29,\"trunc-text-by-char\",[[25,[\"event\",\"description\"]],150],null],1],null]],null],false],[0,\"\\n    \"],[1,[25,[\"event\",\"diff\"]],true],[0,\"\\n\"],[4,\"if\",[[25,[\"event\",\"link\"]]],null,{\"statements\":[[4,\"link-to\",[[25,[\"event\",\"link\"]],[25,[\"event\",\"tableId\"]]],[[\"class\"],[\"event-link\"]],{\"statements\":[[0,\"        \"],[1,[29,\"fa-icon\",[\"external-link\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-admin/pods/components/matter-update/template.hbs"
    }
  });

  _exports.default = _default;
});